/**
 * 지갑 관련 api 모듈 입니다.
 */

import api from './api.js';

/**
 * 내 잔여 포인트 조회 api 입니다.
 */
export const apiMyPoint = async () => {
  const bodyData = '';
  const requestData = {
    data: bodyData,
  };

  const { data } = await api.post(
    '/wallet/mypoint',
    JSON.stringify(requestData)
  );

  return data.data;
};

/**
 * 포인트 충전 api 입니다.
 *
 * @param {number} addPoint 구매할 포인트
 */
export const apiPointCharge = async addPoint => {
  const bodyData = {
    addPoint,
  };
  const requestData = {
    data: bodyData,
  };

  const { data } = await api.post(
    '/wallet/pointcharge',
    JSON.stringify(requestData)
  );

  return data.data;
};
