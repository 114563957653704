import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import { Link, useNavigate } from 'react-router-dom';
import apiCode from '../lib/api/apiCode';
import { apiMyInfo } from '../lib/api/apiMyPage';
import { apiCube } from '../lib/api/apiCube';
import { CopyToClipboard } from "react-copy-to-clipboard/src";


const MyPage = () => {


  const navigate = useNavigate();
  const [avatar, setAvatar] = useState(
    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
  );
  const [myPoint, setMyPoint] = useState(0);
  const [myBundleNum, setMyBundleNum] = useState(0);
  const [referralCode, setReferralCode] = useState(0);
  const [nickName, setNickName] = useState(0);

  const myPoint2 = myPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // 내 정보 확인 api 호출
  const apiCallMyInfo = async () => {
    const data = await apiMyInfo();
    // 세션이 끊어진 상태
    if (data.resultCode === apiCode.error.needLogin) {
      navigate('/login');
      return;
    }

    if (data.resultCode === apiCode.error.success) {
      setAvatar(data.avatarUrl);
      setMyPoint(data.point);
      setMyBundleNum(data.bundleNum);
      setNickName(data.nickName);
    }
  };

  useEffect(() => {
    apiCallMyInfo();
  }, []);

  const apiCallCube = async () => {
    const data = await apiMyInfo();
    // 세션이 끊어진 상태
    if (data.resultCode === apiCode.error.needLogin) {
      navigate('/login');
      return;
    }

    if (data.resultCode === apiCode.error.success) {
      setReferralCode(data.referralCode);
    }
  };

  useEffect(() => {
    apiCallCube();
  }, []);




  return (
    <Container>
      <ul className="editbox">
        <li>
          <Avatar className="img" src={avatar} size={120} round={150}></Avatar>
          <text className="nickname"> &nbsp;&nbsp;&nbsp;{nickName}</text>
        </li>
        <li>
          <Link to={'/edit_profile'} style={{ textDecoration: "none" }}>
            <br />
            <br />
            <button className="edit">내 정보 수정</button>
          </Link>
        </li>
      </ul>

      <ul className="infobox">
        <li className="info">
          <text>
            보유 포인트 <br /><br />{myPoint2} 원
          </text>
        </li>
        <li className="info">
          <Link to={'/mycube'} className="link" style={{ textDecoration: "none" }}>
            <text>
              보유 큐브 <br /><br />{myBundleNum} 개
            </text>
          </Link>
        </li>
      </ul>

      <ul className="helpbox">
        <li>
          <Link to={'/buy_history'} style={{ textDecoration: "none" }}>
            <text>거래 내역</text>
          </Link>
        </li>
        <li></li>
      </ul>
      <ul className="helpbox">
        <li>
          <Link to={'/point'} style={{ textDecoration: "none" }}>
            <text>포인트 충전</text>
          </Link>
        </li>
        <li></li>
      </ul>
      <ul className="helpbox">
        <li>
          <text>추천인 코드<br /><span>{referralCode}</span></text>
        </li>
        <li>
          <CopyToClipboard className="referralbtn" text={referralCode} onCopy={() => alert("추천인 코드가 복사되었습니다!")}>
            <button>복사하기</button></CopyToClipboard>

        </li>
      </ul>
    </Container>
  );
};

export default MyPage;

const Container = styled.div`
  display: block;
  font-family: 'nanumsquare';
  text-decoration: none;
  min-height: 80vh;
  @media only screen and (min-width: 1200px){
    ul{
      width: 40%;
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-left: 30%;
    }
    li+li{
    }
    text{
      font-weight: 600;
      font-size: 25px;
      color: black;
      text-decoration: none;
    }
    .nickname{
      font-size: 20px;
    }
    .editbox{
      padding-top: 50px;
    }
    .edit{
      width: 120px;
      height: 45px;
      font-size: 18px;
      color: white;
      background:#ff5100;
      border: none;
      border-radius: 8px;
    }
    .referralbtn{
      width: 120px;
      height: 45px;
      font-size: 18px;
      color: white;
      background:#ff5100;
      border: none;
      border-radius: 8px;
    }
    .infobox{
      padding-top: 70px;
      padding-bottom:70px;
    }
    .info{
      border: 1px solid none;
      border-radius: 8px;
      background: #ff51001a;
      width: 49.5%;
      padding: 30px 30px;
    }
    .helpbox{
      padding-bottom: 50px;
    }
    span{
      color: gray;
      font-size: 20px;
    }
    }
`;