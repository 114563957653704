import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import apiCode from '../lib/api/apiCode';
import { apiTradeList } from '../lib/api/apiMyPage';

const MyPage = () => {
  const navigate = useNavigate();

  const [totalNum, setTotalNum] = useState(0);
  const [tradeList, setTradeList] = useState([]);

  // 거래 내역 리스트 api 호출
  const apiCallTradeList = async page => {
    const data = await apiTradeList(page);
    // 세션이 끊어진 상태
    if (data.resultCode === apiCode.error.needLogin) {
      navigate('/login');
      return;
    }

    if (data.resultCode === apiCode.error.success) {
      setTotalNum(data.totalNum);
      setTradeList(data.tradeList);
    }
  };

  useEffect(() => {
    apiCallTradeList(1);
  }, []);

    const todayTime = () => {
        let now = new Date();  // 현재 날짜 및 시간
        let todayYear = now.getFullYear(); 
        let todayMonth = now.getMonth() + 1;
        let todayDate = now.getDate();
        return todayYear + '.' + todayMonth + '.' + todayDate
    }


  return (
    <NavMenu>
      <ul className='title'>
        <li >
          거래내역
        </li>
        <li>

        </li>
      </ul>
    {tradeList.map(item => (
     <ul className='contents'>
     <li key={item.no}>
       <text>{item.title}  </text>
     </li>
     <li>
     <text>{todayTime()}</text>
     </li>
     </ul>
   ))}
</NavMenu>
  );
};



export default MyPage;

const NavMenu = styled.ul`
  display: flex;
  width: 100%;
  min-height: 80vh;
  font-family: 'NanumSquareBold';
  flex-direction: column;
  padding-top: 80px;
  ul{
    width: 52.5%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 25%;
    margin-top: 20px;
  
  }
  li+li{
  }
  .title {
    text-decoration: none;
    color: black;
    padding-bottom: 15px;
    margin-bottom:35px;
    font-size: 40px;
    border-bottom: 1px solid lightgray;
  }
  .contents {
    text-decoration: none;
    color: black;
    padding: 5px 5px;
    font-size: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid lightgray;
  }
  @media only screen and (min-width: 601px) and (max-width:768px){
    ul{
      width: 52.5%;
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-left: 25%;
      margin-top: 10px;
    }
    li+li{
    }
    .cube_list {
      text-decoration: none;
      color: black;
      display: block;
      font-size: 12px;
      text-align: right;
      padding: 5px 5px;
    }
    .list_title {
      text-decoration: none;
      color: black;
      display: block;
      text-align: left;
      padding: 5px 5px;
      font-size: 16px;
    }
    .list_title2 {
      text-decoration: none;
      color: black;
      display: block;
      text-align: right;
      padding: 5px 5px;
      font-size: 16px;
    }
  }
  @media only screen and (max-width:600px){
    ul{
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-top: 10px;
    }
    li+li{
    }
    .cube_list {
      text-decoration: none;
      color: black;
      display: block;
      font-size: 10px;
      text-align: right;
      padding: 5px 5px;
    }
    .list_title {
      text-decoration: none;
      color: black;
      display: block;
      text-align: left;
      padding: 5px 5px;
      font-size: 16px;
    }
    .list_title2 {
      text-decoration: none;
      color: black;
      display: block;
      text-align: right;
      padding: 5px 5px;
      font-size: 16px;
    }
  }
`;

