import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Cube_Success = () => {
  const { state } = useLocation();
  const [address, setAddress] = useState('');
  const [myPoint, setMyPoint] = useState(0);
  const [cubeNum, setCubeNum] = useState(0);
  const [totalCubePrice, setTotalCubePrice] = useState(0);
  const [cubeInfoList, setCubeInfoList] = useState([]);
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  
  const nowPoint = myPoint - totalCubePrice;
  const nowPoint2 = nowPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const totalCubePrice2 = totalCubePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const handleToggleOpen = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  useEffect(() => {
    if (state) {
      setAddress(state.address);
      setCubeNum(state.cubeNum);
      setMyPoint(state.myPoint);
      setTotalCubePrice(state.totalCubePrice);
      setCubeInfoList(state.cubeInfoList);
    }
  }, []);
  return (
    <>
    <Wrapper>
      <ul className="title">
        <li>
          <h1>결제 완료</h1>
        </li>
        <li></li>
      </ul>
      <ul className="cube_info">
        <li>
          <text>{address}</text>
        </li>
        <li>
          <button onClick={handleToggleOpen}>
            더보기
          </button>
        </li>
      </ul>
    </Wrapper>
    <NavMenu isToggleOpen={isToggleOpen}>
      <ul>
            <li>
              <text className="list_title">Block ID</text>
              {cubeInfoList.map(item => (
                <text className="cube_list" key={item.cubeNo}>
                  {item.cubeNo}
                </text>
              ))}
            </li>
            <li>
              <text className="list_title2">경도/위도</text>
              {cubeInfoList.map(item => (
                <text className="cube_list" key={item.cubeNo}>
                  {item.longitude}, {item.latitude}
                </text>
              ))}
            </li>
            </ul>
          </NavMenu>
      <Wrap>
        <ul className="info">
          <li>
            <text>구매한 큐브</text>
          </li>
          <li>
            <text>{cubeNum}/800</text>
          </li>
        </ul>
        <ul className="info">
          <li>
            <text>사용한  포인트</text>
          </li>
          <li>
            <text>{totalCubePrice2}P</text>
          </li>
        </ul>
        <ul className="info">
          <li>
            <text>잔여 포인트</text>
          </li>
          <li>
            <text>{nowPoint2}P</text>
          </li>
        </ul>
        <ul>
          <li></li>
          <li>
            <Link to={'/cube'}>
              <button className="back_btn">돌아가기</button>
            </Link>
          </li>
        </ul>
      </Wrap>
      </>
  );
};

export default Cube_Success;
  const Wrapper = styled.div`
  display: flex;
  width: 100%;
  font-family: 'NanumSquareBold';
  flex-direction: column;
  ul{
    width: 52.5%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 25%;
    margin-top: 20px;

  }
  li+li{
  }
  .title{
    margin-top: 100px;
    border-bottom: 1px solid lightgray;
    padding-bottom: -500px;
  }

  .cube_list {
    text-decoration: none;
    display: block;
  }

  .info {
    padding-top: 40px;
    color: black;
    font-size: 22px;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
  }
  
.back_btn{
    padding: 10px 5px; 10px; 5px;
    margin-top: 80px;
    margin-bottom: 50px;
    width: 120px;
    color: white;
    background: #FF5100;
    text-align: center;
    border:none;
    border-radius: 5px;
  }

  button {
    padding: 5px 20px 5px 20px;
    background: none;
    border-radius: 5px;
    margin-top: 55px;
    margin-bottom: 10px;
    font-family: 'NanumSquareBold';
  }
  @media only screen and (min-width: 769px) and (max-width:1199px){
    ul{
      width: 52.5%;
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-left: 25%;
      margin-top: 20px;
  
    }
    li+li{
    }
    .title{
      margin-top: 100px;
      border-bottom: 1px solid lightgray;
      font-size: 15px;
    }
  
    .cube_list {
      text-decoration: none;
      display: block;
    }
  
    .info {
      padding-top: 40px;
      color: black;
      font-size: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid lightgray;
    }
    
  .back_btn{
      padding: 10px 5px; 10px; 5px;
      margin-top: 80px;
      margin-bottom: 50px;
      width: 120px;
      color: white;
      background: #FF5100;
      text-align: center;
      border:none;
      border-radius: 5px;
    }
  
    button {
      padding: 5px 20px 5px 20px;
      background: none;
      border-radius: 5px;
      margin-top: 55px;
      margin-bottom: 10px;
      font-family: 'NanumSquareBold';
    }
  }
  @media only screen and (min-width: 601px) and (max-width:768px){
    ul{
      width: 52.5%;
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-left: 25%;
      margin-top: 20px;
  
    }
    li+li{
    }
    .title{
      margin-top: 100px;
      border-bottom: 1px solid lightgray;
      font-size: 13px;
    }
  
    .cube_list {
      text-decoration: none;
      display: block;
    }
  
    .info {
      padding-top: 40px;
      color: black;
      font-size: 18px;
      padding-bottom: 20px;
      border-bottom: 1px solid lightgray;
    }
    
  .back_btn{
      padding: 10px 5px; 10px; 5px;
      margin-top: 80px;
      width: 100px;
      color: white;
      background: #FF5100;
      text-align: center;
      border:none;
      border-radius: 5px;
    }
  
    button {
      padding: 5px 20px 5px 20px;
      font-size: 12px;
      background: none;
      border-radius: 5px;
      margin-top: 55px;
      margin-bottom: 10px;
      font-family: 'NanumSquareBold';
    }
  }
  @media only screen and (max-width:600px){
    ul{
      width: 52.5%;
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-left: 25%;
      margin-top: 15px;
  
    }
    li+li{
    }
    .title{
      margin-top: 90px;
      border-bottom: 1px solid lightgray;
      font-size: 11px;
    }
  
    .cube_list {
      text-decoration: none;
      display: block;
    }
  
    .info {
      padding-top: 40px;
      color: black;
      font-size: 15px;
      padding-bottom: 20px;
      border-bottom: 1px solid lightgray;
    }
    
  .back_btn{
      padding: 10px 5px; 10px; 5px;
      margin-top: 60px;
      margin-bottom:100px;
      width: 100px;
      color: white;
      background: #FF5100;
      text-align: center;
      border:none;
      border-radius: 5px;
    }
  
    button {
      padding: 5px 20px 5px 20px;
      font-size: 12px;
      background: none;
      border-radius: 5px;
      margin-top: 20px;
      margin-bottom: 10px;
      font-family: 'NanumSquareBold';
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100%;
  padding-bottom: 230px;
  font-family: 'NanumSquareBold';
  flex-direction: column;
  ul{
    width: 52.5%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 25%;
    margin-top: 20px;

  }
  li+li{
  }
  .title{
    margin-top: 100px;
    border-bottom: 1px solid lightgray;
    padding-bottom: -500px;
  }

  .cube_list {
    text-decoration: none;
    display: block;
  }

  .info {
    padding-top: 40px;
    color: black;
    font-size: 22px;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
  }
  
.back_btn{
    padding: 10px 5px; 10px; 5px;
    margin-top: 80px;
    margin-bottom: 50px;
    width: 120px;
    color: white;
    background: #FF5100;
    text-align: center;
    border:none;
    border-radius: 5px;
  }

  button {
    padding: 5px 20px 5px 20px;
    background: none;
    border-radius: 5px;
    margin-top: 55px;
    margin-bottom: 10px;
    font-family: 'NanumSquareBold';
  }
  @media only screen and (min-width: 769px) and (max-width:1199px){
    ul{
      width: 52.5%;
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-left: 25%;
      margin-top: 20px;
  
    }
    li+li{
    }
    .title{
      margin-top: 100px;
      border-bottom: 1px solid lightgray;
      font-size: 15px;
    }
  
    .cube_list {
      text-decoration: none;
      display: block;
    }
  
    .info {
      padding-top: 40px;
      color: black;
      font-size: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid lightgray;
    }
    
  .back_btn{
      padding: 10px 5px; 10px; 5px;
      margin-top: 80px;
      margin-bottom: 50px;
      width: 120px;
      color: white;
      background: #FF5100;
      text-align: center;
      border:none;
      border-radius: 5px;
    }
  
    button {
      padding: 5px 20px 5px 20px;
      background: none;
      border-radius: 5px;
      margin-top: 55px;
      margin-bottom: 10px;
      font-family: 'NanumSquareBold';
    }
  }
  @media only screen and (min-width: 601px) and (max-width:768px){
    ul{
      width: 52.5%;
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-left: 25%;
      margin-top: 20px;
  
    }
    li+li{
    }
    .title{
      margin-top: 100px;
      border-bottom: 1px solid lightgray;
      font-size: 13px;
    }
  
    .cube_list {
      text-decoration: none;
      display: block;
    }
  
    .info {
      padding-top: 40px;
      color: black;
      font-size: 18px;
      padding-bottom: 20px;
      border-bottom: 1px solid lightgray;
    }
    
  .back_btn{
      padding: 10px 5px; 10px; 5px;
      margin-top: 80px;
      width: 100px;
      color: white;
      background: #FF5100;
      text-align: center;
      border:none;
      border-radius: 5px;
    }
  
    button {
      padding: 5px 20px 5px 20px;
      font-size: 12px;
      background: none;
      border-radius: 5px;
      margin-top: 55px;
      margin-bottom: 10px;
      font-family: 'NanumSquareBold';
    }
  }
  @media only screen and (max-width:600px){
    ul{
      width: 52.5%;
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-left: 25%;
      margin-top: 15px;
  
    }
    li+li{
    }
    .title{
      margin-top: 90px;
      border-bottom: 1px solid lightgray;
      font-size: 11px;
    }
  
    .cube_list {
      text-decoration: none;
      display: block;
    }
  
    .info {
      padding-top: 40px;
      color: black;
      font-size: 15px;
      padding-bottom: 20px;
      border-bottom: 1px solid lightgray;
    }
    
  .back_btn{
      padding: 10px 5px; 10px; 5px;
      margin-top: 60px;
      margin-bottom:100px;
      width: 100px;
      color: white;
      background: #FF5100;
      text-align: center;
      border:none;
      border-radius: 5px;
    }
  
    button {
      padding: 5px 20px 5px 20px;
      font-size: 12px;
      background: none;
      border-radius: 5px;
      margin-top: 20px;
      margin-bottom: 10px;
      font-family: 'NanumSquareBold';
    }
  }
`;

const NavMenu = styled.ul`
  display: ${props => (props.isToggleOpen ? 'block' : 'none')};
  width: 100%;
  height: auto;
  min-height: 100%;
  font-family: 'NanumSquareBold';
  flex-direction: column;
  ul{
    width: 52.5%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 25%;
    margin-top: 20px;

  
  }
  li+li{
  }
  .cube_list {
    text-decoration: none;
    color: black;
    display: block;
    font-size: 16px;
    text-align: right;
    padding: 5px 5px;
  }
  .list_title {
    text-decoration: none;
    color: black;
    display: block;
    text-align: left;
    padding: 5px 5px;
    font-size: 22px;
  }
  .list_title2 {
    text-decoration: none;
    color: black;
    display: block;
    text-align: right;
    padding: 5px 5px;
    font-size: 22px;
  }
  @media only screen and (min-width: 601px) and (max-width:768px){
    ul{
      width: 52.5%;
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-left: 25%;
      margin-top: 10px;
    }
    li+li{
    }
    .cube_list {
      text-decoration: none;
      color: black;
      display: block;
      font-size: 12px;
      text-align: right;
      padding: 5px 5px;
    }
    .list_title {
      text-decoration: none;
      color: black;
      display: block;
      text-align: left;
      padding: 5px 5px;
      font-size: 16px;
    }
    .list_title2 {
      text-decoration: none;
      color: black;
      display: block;
      text-align: right;
      padding: 5px 5px;
      font-size: 16px;
    }
  }
  @media only screen and (max-width:600px){
    ul{
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-top: 10px;
    }
    li+li{
    }
    .cube_list {
      text-decoration: none;
      color: black;
      display: block;
      font-size: 10px;
      text-align: right;
      padding: 5px 5px;
    }
    .list_title {
      text-decoration: none;
      color: black;
      display: block;
      text-align: left;
      padding: 5px 5px;
      font-size: 16px;
    }
    .list_title2 {
      text-decoration: none;
      color: black;
      display: block;
      text-align: right;
      padding: 5px 5px;
      font-size: 16px;
    }
  }
`;