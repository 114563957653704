import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { useRef, useState, useCallback, useEffect } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import tilebelt from '@mapbox/tilebelt';
import apiCode from '../lib/api/apiCode';
import { apiMapInfo, apiCubeInfo } from '../lib/api/apiCube';
import { apiCheckLogin } from '../lib/api/apiAuth';
import { ToastContainer, toast } from 'react-toastify';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MIN_ZOOM = 15.5;
const MAX_PITCH = 70;
const MAX_PURCHASE_CUBE_NUM = 800;
const SOLD_COLOR = '#d95d39';

const Cube = () => {
  const navigate = useNavigate();

  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const soldCubeRef = useRef({});
  const selectCubeRef = useRef({});
  const addSelectCubeRef = useRef([]);
  const cursorOnCubeNoRef = useRef(-1);
  const isDragRef = useRef(false);
  const firstSelectLongitudeRef = useRef(-1);
  const firstSelectLatitudeRef = useRef(-1);
  const distanceCriteriaLongitudeRef = useRef(-1);
  const distanceCriteriaLatitudeRef = useRef(-1);

  const [mapStyle, setMapStyle] = useState(apiCode.service.mapStyle.baseMap);
  const [mapUri, setMapUri] = useState('mapbox://styles/mapbox/streets-v11');
  const [reloadLng, setReloadLng] = useState(128.6014);
  const [reloadLat, setReloadLat] = useState(35.8714);
  const [reloadZoom, setReloadZoom] = useState(16.5);
  const [moveEnd, setMoveEnd] = useState(false);
  const [showGrad, setShowGrad] = useState(true);
  const [firstCube, setFirstCube] = useState('');
  const [address, setAddress] = useState('');
  const [selectedCubeList, setSelectedCubeList] = useState([]);
  const [oneCubePrice, setOneCubePrice] = useState(0);
  const [totalCubePrice, setTotalCubePrice] = useState(0);

  const oneCubePrice2 = oneCubePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const totalCubePrice2 = totalCubePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // 맵 정보 받기
  const apiCallMapInfo = async cubeNoList => {
    const data = await apiMapInfo(cubeNoList);
    return data;
  };

  // 로그인 확인 api 호출
  const apiCallCheckLogin = async () => {
    const data = await apiCheckLogin();
    if (data.resultCode !== apiCode.error.success) {
      navigate('/login');
      return false;
    }
    return true;
  };

  // 맵 스타일 변경
  const handleChangeMapStyle = style => {
    setMapStyle(style);
  };

  // 전체 취소 버튼 클릭
  const handleAllClear = async () => {
    isDragRef.current = false;
    cursorOnCubeNoRef.current = -1;
    distanceCriteriaLatitudeRef.current = -1;
    distanceCriteriaLongitudeRef.current = -1;
    addSelectCubeRef.current = [];
    setSelectedCubeList([]);

    for (let key in selectCubeRef.current) {
      delete selectCubeRef.current[key];
    }

    if (showGrad) {
      const filter = ['match', ['get', 'id'], [0], true, false];
      mapRef.current.setFilter('gridLayerChoice', filter);
    }
  };

  // 구매하기 버튼 클릭
  const handleBuyCube = async () => {
    const checkLoginStatus = await apiCallCheckLogin();
    if (!checkLoginStatus) return;

    if (selectedCubeList.length === 0) {
      toast.success('구매할 블럭을 선택해 주세요.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
      return;
    }

    navigate('/buy_cube', {
      state: {
        cubeList: selectedCubeList,
      },
    });
  };

  // 정보 박스 업데이트
  const updateInfoBox = useCallback(async () => {
    console.log("test")
    if (selectedCubeList.length === 1 && selectedCubeList[0] !== firstCube) {
      console.log("test2")
      setFirstCube(selectedCubeList[0]);
      const data = await apiCubeInfo(selectedCubeList[0]);
      console.log(data)
      if (data.isDaegu === false) { 
        // 선택한 블럭이 대구가 아닐 경우
        handleAllClear();
        toast.success('대구광역시만 선택해 주세요.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
        });
        return;
      }

      if (data.resultCode === apiCode.error.success) {
        const cubeNum = selectedCubeList.length;
        const onePrice = data.cubePrice;
        const totalPrice = onePrice * cubeNum;
        console.log("리액트"+onePrice);

        setAddress(data.address);
        setOneCubePrice(onePrice);
        setTotalCubePrice(totalPrice);
      }
    } else {
      const cubeNum = selectedCubeList.length;
      const totalPrice = oneCubePrice * cubeNum;

      setTotalCubePrice(totalPrice);
    }
  }, [firstCube, oneCubePrice, selectedCubeList]);

  // 정보 박스 초기화
  const clearInfoBox = () => {
    setFirstCube('');
    setAddress('');
    setOneCubePrice(0);
    setTotalCubePrice(0);
  };

  const mapStyleUpdate = useCallback(() => {
    if (!mapRef.current) return;

    setReloadLng(mapRef.current.getCenter().lng);
    setReloadLat(mapRef.current.getCenter().lat);
    setReloadZoom(mapRef.current.getZoom());

    if (mapStyle === apiCode.service.mapStyle.baseMap) {
      setShowGrad(true);
      setMapUri('mapbox://styles/mapbox/streets-v11');
    } else if (mapStyle === apiCode.service.mapStyle.satellite) {
      setShowGrad(true);
      setMapUri('mapbox://styles/mapbox/satellite-v9');
    } else if (mapStyle === apiCode.service.mapStyle.threeD) {
      setShowGrad(false);
      setMapUri('mapbox://styles/mapbox/satellite-v9');
    }
  }, [mapStyle]);

  // 선택된 타일 리턴
  const getSelectTiles = (lng1, lat1, lng2, lat2) => {
    let lngMax = 0;
    let lngMin = 0;
    let latMax = 0;
    let latMin = 0;
    if (lng1 > lng2) {
      lngMax = lng1;
      lngMin = lng2;
    } else {
      lngMax = lng2;
      lngMin = lng1;
    }
    if (lat1 > lat2) {
      latMax = lat1;
      latMin = lat2;
    } else {
      latMax = lat2;
      latMin = lat1;
    }
    const tileMin = tilebelt.pointToTile(lngMin, latMin, 21);
    const tileMax = tilebelt.pointToTile(lngMax, latMax, 21);

    let tileList = [];
    for (let i = tileMin[0]; i < tileMax[0] + 1; i++) {
      for (let j = tileMax[1]; j < tileMin[1] + 1; j++) {
        tileList.push([i, j, 21]);
      }
    }

    return tileList;
  };

  // 화면 타일 리턴
  const getTiles = (bounds, zoom) => {
    const tileMin = tilebelt.pointToTile(
      bounds.getSouthWest().lng,
      bounds.getSouthWest().lat,
      zoom
    );
    const tileMax = tilebelt.pointToTile(
      bounds.getNorthEast().lng,
      bounds.getNorthEast().lat,
      zoom
    );
    let tiles = [];
    for (let i = tileMin[0]; i < tileMax[0] + 1; i++) {
      for (let j = tileMax[1]; j < tileMin[1] + 1; j++) {
        tiles.push([i, j, zoom]);
      }
    }

    return tiles;
  };

  // geoJson 데이터 셋팅 (일반 맵 정보)
  const makeGrid = useCallback(async () => {
    if (!showGrad) return;
    if (mapRef.current.getZoom() < MIN_ZOOM) return;

    const bounds = mapRef.current.getBounds();
    const tileList = getTiles(bounds, 21);
    let cubeNoList = [];

    for (let i = 0; i < tileList.length; i++) {
      const quadKey = tilebelt.tileToQuadkey(tileList[i]);
      cubeNoList.push(quadKey);
    }
    const cubeInfo = await apiCallMapInfo(cubeNoList);

    const grid = {
      type: 'FeatureCollection',
      features: [],
    };

    for (let i = 0; i < tileList.length; i++) {
      const geoJson = tilebelt.tileToGeoJSON(tileList[i]);
      const quadKey = tilebelt.tileToQuadkey(tileList[i]);
      let color = '#000000';
      let colorOpacity = 0.1;

      if (cubeInfo.cubeInfoList[quadKey]) {
        const cubeItem = cubeInfo.cubeInfoList[quadKey];
        soldCubeRef.current[quadKey] = cubeItem.bundleNo;
        color = SOLD_COLOR;
        colorOpacity = 0.5;
      }

      const centerLongitude =
        geoJson.coordinates[0][0][0] +
        (geoJson.coordinates[0][3][0] - geoJson.coordinates[0][0][0]) * 0.5;
      const centerLatitude =
        geoJson.coordinates[0][0][1] +
        (geoJson.coordinates[0][1][1] - geoJson.coordinates[0][0][1]) * 0.5;

      grid.features.push({
        type: 'Feature',
        geometry: geoJson,
        properties: {
          id: quadKey,
          color: color,
          colorOpacity: colorOpacity,
          longitude: centerLongitude,
          latitude: centerLatitude,
        },
      });
    }

    return grid;
  }, []);

  // 지도 선택 레이어 필터 업데이트
  const updateGridLayerChoice = useCallback(
    arrCube => {
      setSelectedCubeList(arrCube);
      if (arrCube.length === 0) {
        const filter = ['match', ['get', 'id'], [0], true, false];
        mapRef.current.setFilter('gridLayerChoice', filter);
        return;
      }

      const filter = ['match', ['get', 'id'], arrCube, true, false];
      mapRef.current.setFilter('gridLayerChoice', filter);
    },
    [setSelectedCubeList]
  );

  // map source, layer 삭제
  const removeBaseMapSource = () => {
    if (mapRef.current.getLayer('gridLayerChoice')) {
      mapRef.current.removeLayer('gridLayerChoice');
    }
    if (mapRef.current.getLayer('gridLayerColor')) {
      mapRef.current.removeLayer('gridLayerColor');
    }
    if (mapRef.current.getSource('gridSource')) {
      mapRef.current.removeSource('gridSource');
    }
  };

  // 기본 맵 지도 셋팅
  const createBaseMap = useCallback(async () => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: mapUri,
      center: [reloadLng, reloadLat],
      zoom: reloadZoom,
      scrollZoom: true,
    });

    mapRef.current.dragRotate.disable();
    mapRef.current.touchZoomRotate.disableRotation();
    mapRef.current.doubleClickZoom.disable();
    mapRef.current.touchPitch.disable();

    // 지도 언어 셋팅
    const language = new MapboxLanguage();
    mapRef.current.addControl(language);
  }, [mapUri, reloadLat, reloadLng, reloadZoom]);

  const addSourceBaseMap = useCallback(async () => {
    mapRef.current.once('load', async () => {
      removeBaseMapSource();

      let grid = await makeGrid();
      mapRef.current.addSource('gridSource', {
        type: 'geojson',
        data: grid,
        generateId: true,
      });

      mapRef.current.addLayer({
        id: 'gridLayerColor',
        source: 'gridSource',
        type: 'fill',
        paint: {
          'fill-color': ['get', 'color'],
          'fill-opacity': ['get', 'colorOpacity'],
        },
      });

      mapRef.current.addLayer({
        id: 'gridLayerChoice',
        source: 'gridSource',
        type: 'fill',
        paint: {
          'fill-color': '#d30d77',
          'fill-opacity': 0.5,
        },
        filter: ['==', ['get', 'id'], -1],
      });

      mapRef.current.setLayerZoomRange('gridLayerColor', MIN_ZOOM, 22);
      mapRef.current.setLayerZoomRange('gridLayerChoice', MIN_ZOOM, 22);

      if (Object.keys(selectCubeRef.current).length > 0) {
        const arrCube = Object.keys(selectCubeRef.current);
        const filter = ['match', ['get', 'id'], arrCube, true, false];
        mapRef.current.setFilter('gridLayerChoice', filter);
      }

      mapRef.current.on('click', 'gridLayerColor', function (e) {
        var selectIndex = e.features[0].properties.id;
        if (soldCubeRef.current[selectIndex]) {
          return;
        }

        if (isDragRef.current) {
          isDragRef.current = false;
          mapRef.current.scrollZoom.enable();
          cursorOnCubeNoRef.current = -1;

          for (let quadKey of addSelectCubeRef.current) {
            selectCubeRef.current[quadKey] = true;
          }
          addSelectCubeRef.current = [];

          updateGridLayerChoice(Object.keys(selectCubeRef.current));
        } else {
          if (
            selectCubeRef.current[selectIndex] ||
            addSelectCubeRef.current.includes(selectIndex)
          ) {
            delete selectCubeRef.current[selectIndex];
            if (Object.keys(selectCubeRef.current).length === 0) {
              distanceCriteriaLatitudeRef.current = -1;
              distanceCriteriaLongitudeRef.current = -1;
            }
          } else {
            // 큐브 최대 구매 개수 확인
            if (
              Object.keys(selectCubeRef.current).length >= MAX_PURCHASE_CUBE_NUM
            ) {
              return;
            }

            isDragRef.current = true;
            mapRef.current.scrollZoom.disable();
            firstSelectLongitudeRef.current =
              e.features[0].properties.longitude;
            firstSelectLatitudeRef.current = e.features[0].properties.latitude;

            if (Object.keys(selectCubeRef.current).length === 0) {
              distanceCriteriaLongitudeRef.current =
                e.features[0].properties.longitude;
              distanceCriteriaLatitudeRef.current =
                e.features[0].properties.latitude;
            }

            selectCubeRef.current[selectIndex] = true;
          }
          updateGridLayerChoice(Object.keys(selectCubeRef.current));
        }
      });

      mapRef.current.on('mousemove', 'gridLayerColor', function (e) {
        var selectIndex = e.features[0].properties.id;
        if (!isDragRef.current) return;
        if (cursorOnCubeNoRef.current === selectIndex) return;
        if (soldCubeRef.current[selectIndex]) return;

        cursorOnCubeNoRef.current = selectIndex;

        // 드레그에 포함되는 타일셋 계산
        const tileList = getSelectTiles(
          firstSelectLongitudeRef.current,
          firstSelectLatitudeRef.current,
          e.features[0].properties.longitude,
          e.features[0].properties.latitude
        );

        // 큐브 최대 구매 개수 확인
        const selectedCubeNum = Object.keys(selectCubeRef.current).length;
        const curCubeNum = selectedCubeNum + tileList.length;
        if (curCubeNum > MAX_PURCHASE_CUBE_NUM) return;

        addSelectCubeRef.current = [];
        for (let i = 0; i < tileList.length; i++) {
          const quadKey = tilebelt.tileToQuadkey(tileList[i]);
          if (
            !soldCubeRef.current[quadKey] &&
            !selectCubeRef.current[quadKey]
          ) {
            addSelectCubeRef.current.push(quadKey);
          }
        }

        const arrCube = addSelectCubeRef.current.concat(
          Object.keys(selectCubeRef.current)
        );
        updateGridLayerChoice(arrCube);
      });
    });
  }, [makeGrid, updateGridLayerChoice]);

  // 3d map source, layer 삭제
  const remove3dMapSource = () => {
    if (mapRef.current.getLayer('sky')) {
      mapRef.current.removeLayer('sky');
    }
    if (mapRef.current.getSource('mapbox-dem')) {
      mapRef.current.removeSource('mapbox-dem');
    }
  };

  // 3d map 지도 셋팅
  const create3dMapSource = useCallback(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: mapUri,
      center: [reloadLng, reloadLat],
      zoom: reloadZoom,
      pitch: MAX_PITCH,
      bearing: 80,
    });
    mapRef.current.setMaxPitch(MAX_PITCH);

    mapRef.current.on('load', () => {
      remove3dMapSource();

      mapRef.current.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        tileSize: 512,
        maxzoom: 14,
      });

      mapRef.current.setTerrain({
        source: 'mapbox-dem',
        exaggeration: 1.5,
      });

      mapRef.current.addLayer({
        id: 'sky',
        type: 'sky',
        paint: {
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun': [0.0, 0.0],
          'sky-atmosphere-sun-intensity': 15,
        },
      });
    });
  }, [mapUri, reloadLat, reloadLng, reloadZoom]);

  // 그리드 업데이트
  const updateGrid = useCallback(async () => {
    if (mapRef.current.getZoom() < MIN_ZOOM) return;
    if (!mapRef.current.getSource('gridSource')) return;

    const grid = await makeGrid();
    mapRef.current.getSource('gridSource').setData(grid);
  }, [makeGrid]);

  useEffect(() => {
    mapStyleUpdate();
  }, [mapStyleUpdate]);

  useEffect(() => {
    mapRef.current = null;
    if (mapStyle === apiCode.service.mapStyle.threeD) {
      create3dMapSource();
    } else {
      createBaseMap();
      addSourceBaseMap();
    }
  }, [mapStyle, createBaseMap, addSourceBaseMap, create3dMapSource]);

  useEffect(() => {
    if (selectedCubeList.length === 0) {
      clearInfoBox();
      return;
    }

    updateInfoBox();
  }, [selectedCubeList, updateInfoBox]);

  useEffect(() => {
    if (!mapRef.current) return;

    updateGrid();
  }, [updateGrid]);

  useEffect(() => {
    if (!mapRef.current) return;
    if (!showGrad) return;

    mapRef.current.once('moveend', async () => {
      if (!moveEnd) return;

      setMoveEnd(false);
      if (mapRef.current.getZoom() < MIN_ZOOM) return;
      if (!mapRef.current.getSource('gridSource')) return;

      const grid = await makeGrid();
      mapRef.current.getSource('gridSource').setData(grid);
    });
  }, [moveEnd, makeGrid, showGrad]);

  useEffect(() => {
    if (!mapRef.current) return;

    mapRef.current.once('move', () => {
      if (moveEnd) return;
      setMoveEnd(true);
    });
  });

  return (
    <>
      <Map>
        <div className="map-style" ref={mapContainerRef}/>
        <div className="info_box">
          <ul className="map">
            <li>
              <button
                className="normal"
                onClick={() => {
                  handleChangeMapStyle(apiCode.service.mapStyle.baseMap);
                }}
              >
                일반지도
              </button>
            </li>
            <li>
              <button
                className="settle"
                onClick={() => {
                  handleChangeMapStyle(apiCode.service.mapStyle.satellite);
                }}
              >
                위성지도
              </button>
            </li>
            <li>
              <button
                className="geo"
                onClick={() => {
                  handleChangeMapStyle(apiCode.service.mapStyle.threeD);
                }}
              >
                지형지도
              </button>
            </li>
          </ul>
          <ul className='info'>
            <li>
              <text>{address}</text>
            </li>
            <li>
            <button className="cancle" onClick={handleAllClear}>
              전체 취소
            </button>
          </li>
          </ul>
          <ul className='info'>
            <li>
              <text>선택된 큐브</text>
            </li>
            <li>
              <text>
                {selectedCubeList.length}/800
              </text>
            </li>
          </ul>
          <ul className='info'>
            <li>
              <text>큐브 가격(1개)</text>
            </li>
            <li>
              <text>
                <text>{oneCubePrice2}P</text>
              </text>
            </li>
          </ul>
          <ul className='info'>
            <li>
              <text>총 가격</text>
            </li>
            <li>
              <text>
                <text>{totalCubePrice2}P</text>
              </text>
            </li>
          </ul>
          <button className="buy_btn" onClick={handleBuyCube}>
            구매하기
          </button>
        </div>
        </Map>
    </>
  );
};

export default Cube;

const Map = styled.div`
position: relative;
list-style: none;
display: flex;
color: black;
min-height: 80vh;
  .map-style {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
  .info_box {
    position: absolute;
    background: white;
    margin-top: 10px;
    padding: 0px 10px 10px 10px;
    margin-left: 30px;
    border-radius: 10px;
  }
  ul{
    display: flex;
    list-style: none;
    border-bottom: 1px solid lightgray;
    justify-content: space-between;
  }
    .info{
    display: flex;
    list-style: none;
    border-bottom: 1px solid lightgray;
    justify-content: space-between;
    padding-top: 30px;
  }
  li+li{
    margin-left: 30px;
  }
  button {
    text-align: right;
    padding: 3px 10px 3px 10px;
    background: none;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 0px;
    font-family: 'NanumSquareBold';
    font-size: 10px;
  }
  .map {
    border-bottom: none;
    background: none;
    padding-top: 10px;
  }
  .normal {
    font-family: 'NanumSquareBold';
    font-size: 14px;
    border: 2px solid black;
    border-radius: 5px;
    background: white;
    
  }
  .settle {
    font-family: 'NanumSquareBold';
    font-size: 14px;
    border: 2px solid black;
    border-radius: 5px;
    background: white;
  }
  .geo {
    font-family: 'NanumSquareBold';
    font-size: 14px;
    border: 2px solid black;
    border-radius: 5px;
    background: white;
  }
  .cancle{
    justify-content: left;
    margin-bottom: 10px;
  }
  .buy_btn {
    font-family: 'NanumSquareBold';
    text-align: center;
    padding: 3% 3%;
    margin-top: 10px;
    color: white;
    width: 100%;
    border: 0;
    background: #ff5100;
    border-radius: 5px;
  }
`;