/**
 * my page 관련 api 모듈 입니다.
 */

import api from './api.js';

/**
 * 내 정보 조회 api 입니다.
 */
export const apiMyInfo = async () => {
  const bodyData = '';
  const requestData = {
    data: bodyData,
  };

  const { data } = await api.post(
    '/mypage/myinfo',
    JSON.stringify(requestData)
  );

  return data.data;
};

/**
 * 내 정보 업데이트 api 입니다.
 *
 * @param {string} nickName 수정할 닉네임
 * @param {string} telNumber 수정할 전화번호
 */
export const apiUpdateMyInfo = async (nickName, telNumber) => {
  const bodyData = {
    nickName,
    telNumber,
  };
  const requestData = {
    data: bodyData,
  };

  const { data } = await api.post(
    '/mypage/update',
    JSON.stringify(requestData)
  );

  return data.data;
};

/**
 * 거래 내역 조회 api 입니다.
 *
 * @param {number} page 요청 페이지 번호
 */
export const apiTradeList = async page => {
  const bodyData = {
    page,
  };
  const requestData = {
    data: bodyData,
  };

  const { data } = await api.post(
    '/mypage/tradelist',
    JSON.stringify(requestData)
  );

  return data.data;
};
