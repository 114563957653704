import styled from "styled-components";




const P_Service = () => {
  return (
    <Wrapper>
        <h1>
          유료 이용약관
        </h1>
        <div className="text"><h2>제 1조(유료서비스의 구매)</h2><br />
          1. 서비스 내에서 회원이 구매한 유료서비스는 해당 서비스 내에서만 이용할 수 있
          으며, 이를 복제, 전송, 대여 등의 방식으로 제 3자에게 이용하게 해서는 안됩니다.<br /><br />
          <h2>제 2 조(구매 철회 등)</h2><br />
          1. ‘회사’와 유료 서비스 이용에 관한 계약을 체결한 ‘회원’은 구매 계약일과 유료
          서비스 이용 가능일 중 늦은 날로부터 7일 이내에 별도의 수수료, 위약금 등의 부
          담 없이 구매 철회를 할 수 있습니다.<br /><br />
          2. ‘회원’은 다음 각 호에 해당하는 경우, ‘회사’의 의사에 반하여 제1항에 따른 구매
          철회를 할 수 없습니다.<br /><br />
          ① ‘회원’이 재화의 획득을 ‘회사’에게 확인한 경우<br /><br />
          ② ‘회원’이 제3자에게 공개 혹은 비공개적으로 재화를 판매하고자 하는 의사를 게
          시하거나 전달한 경우. 이 때, 그 사실을 확인한 때와 관계없이 ‘회원’이 재화의 사
          용권을 확정한 것이 됩니다.<br /><br />
          ③ ‘회원’이 재화를 제3자에게 이전하였거나 이전하기로 약정한 경우<br /><br />
          ④ 구매 즉시 사용되거나 적용되는 유료 콘텐츠 혹은 재화<br /><br />
          ⑤ 부가적인 혜택이 제공되는 콘텐츠 또는 묶음형 판매 콘텐츠에서 추가 혜택이 사
          용되거나 일부가 사용된 콘텐츠<br /><br />
          ⑥ ‘회원’이 이미 재화의 이용에 따른 혜택을 받았거나 권리를 행사한 경우<br /><br />
          ⑦ 콘텐츠의 제공이 개시된 경우<br /><br />
          3. ‘회사’는 본 조 제2항에 따라 구매 철회 등이 불가능한 재화 등의 경우에는 그 사
          실을 ‘회원’이 쉽게 알 수 있는 곳에 명확하게 적시하여 구매 철회 등의 권리 행사가
          방해받지 아니하도록 합니다.<br /><br />
          4. ‘회원’은 본 조 제2항과 제3항의 규정에도 불구하고 ‘서비스’의 내용이 표시, 광
          고 내용과 다르거나 계약 내용과 다르게 이행된 경우에는 구매일 또는 유료서비스
          이용 가능일로부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날로부터 30일 이
          내에 구매 철회를 할 수 있습니다.<br /><br />
          5. 미성년자인 ‘회원’이 ‘서비스’ 내에서 결제가 필요한 재화 혹은 콘텐츠 이용계약
          을 체결하는 경우, ‘회사’는 법정대리인의 동의가 없으면, 미성년자 본인 또는 법정
          대리인이 그 계약을 취소할 수 있다는 내용을 고지하며, 미성년자가 법정 대리인의
          동의 없이 계약을 체결한 때에는 미성년자 본인 또는 법정 대리인은 ‘회사’에 그 예
          약을 취소할 수 있습니다. 다만, 미성년자가 법정대리인이 범위를 정하여 처분을
          허락한 재산으로 재화의 구매 혹은 콘텐츠의 이용계약을 체결한 경우 또는 미성년
          자가 속임수로서 자신을 성년자로 믿게 하거나 법정대리인의 동의가 있는 것으로
          믿게 한 경우에는 취소할 수 없습니다.<br /><br />
          <h2>제 3 조 (과오납급의 환급)</h2><br />
          1. ‘회사’는 과오납금이 발생한 경우, 이용대금의 결제와 동일한 방법으로 과오납금
          전액을 환불하여야 합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사
          전에 고지합니다.<br /><br />
          2. ‘회사’의 책임 있는 사유로 과오납금이 발생한 경우 ‘회사’는 계약비용, 수수료
          등에 관계없이 과오납금 전액을 환불합니다. 다만, ‘회원’의 책임 있는 사유로 과오
          납금이 발생한 경우, ‘회사’가 과오납금을 환불하는데 소요되는 비용은 합리적인
          범위 내에서 ‘회원’이 부담하여야 합니다.<br /><br />
          3. ‘회사’는 ‘회원’이 주장하는 과오납금에 대해 환불을 거부할 경우에 정당하게 이
          용대금이 부과되었음을 입증할 책임을 집니다.<br /><br />
          4. 모바일 결제의 경우, 환급은 ‘서비스’를 이용하고 있는 모바일 기기의 운영체제
          종류에 따라 각 오픈마켓 사업자 또는 회사의 환급정책에 따라 진행됩니다.<br /><br />
          5. ‘회사’는 과오납금의 환급을 처리하기 위해 ‘회원’에게서 제공받은 정보를 통해
          ‘회원’에게 연락할 수 있으며, 필요한 정보의 제공을 요청할 수 있습니다.</div><br />
    </Wrapper>
  );
};

export default P_Service;

const Wrapper = styled.div`
  margin-left: 25%;
  max-width: 50%;
  min-height: 80vh;
  .text{
    text-align:left;
    font-size: 20px;
    padding-bottom: 30px;
  }
  h1{
    font-size: 35px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;