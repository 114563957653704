//import { useState } from "react";
import { Link } from "react-router-dom";
import React, { useState } from 'react';
import styled from "styled-components";
//import { FaBars } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <StyledHeader>
        <FooterMenu>
          <li>
            <Link to={"/service"} className="footer-menu-list">
            이용약관
            </Link>
          </li>
          <li>
            <Link to={"/p_service"} className="footer-menu-list">
              유료이용약관
            </Link>
          </li>
          <li>
            <Link to={"/privacy"} className="footer-menu-list">
              개인정보 이용약관
            </Link>
          </li>
          <li>
            <Link to={"/cs_center"} className="footer-menu-list">
              고객센터
            </Link>
          </li>
        </FooterMenu>
        <text>
        사업자번호: 777-87-01906&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        대표이사: 이선진&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        개인정보책임자: 이선진<br/><br/>
        대구광역시 동구 동대구로 496(신천동) 대구콘텐츠비즈니스센터 201호
        </text>
            
        <div className="foot_logo">
          <Link to={"/"} className="foot-logo-link">
           OTTERLAND
          </Link>
        </div>
        <text1>Copyright ⓒ Rumongstudio Inc. All rights reserved. Since 2022</text1>

      </StyledHeader>
    </>
  );
};

export default Footer;

const StyledHeader = styled.header`
  background-color: #191554;
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-center;
  text{
    color:white;
    padding-top:30px;
    font-size: 13px;
  }
  text1{
    color: white;
    font-size: 13px;
    padding-bottom: 10px;
  }
  .foot_logo {

    .foot-logo-link {
      text-decoration: none;
      font-size: 30px;
      color: #FF5100;
      font-family: 'Aldrich', sans-serif;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 768px){
    text{
      color:white;
      padding-top:30px;
      font-size: 9px;
    }
    text1{
      color: white;
      font-size: 9px;
      padding-bottom: 10px;
    }
    .foot_logo {
  
      .foot-logo-link {
        text-decoration: none;
        font-size: 25px;
        color: #FF5100;
        font-family: 'Aldrich', sans-serif;
        font-weight: bold;
      }
    }
  }
`;

const FooterMenu = styled.ul`
  list-style: none;
  display: flex;
  align-items: flex-center;
  width: 100%;
  justify-content: space-around;
  li{
  }
  .footer-menu-list {
    text-decoration: none;
    padding-top: 20px;
    color: white;
    display: block;
    font-size: 12px;

  }
  @media screen and (max-width: 768px){
    .footer-menu-list{
      font-size: 8px;
    }
  }
`;