import { Link } from "react-router-dom";
import styled from "styled-components";


const Signup_Success = () => {
  return (
    <Wrapper>
        <>
        <div className="text">
        <h1>회원가입이 완료되었습니다</h1>
        <h2>Otterland 함께 Cube의 오너가 되어</h2>
        <h2>다양한 체험들을 즐겨보세요!</h2>
        <Link to={"/login"}>
        <button className="btn_design">로그인</button>
       </Link>
        </div>

        
      </>
    </Wrapper>
  );
};

export default Signup_Success;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  @media only screen and (max-width:600px){
    h1 {
      font-size: 20px;
      font-family: 'nanumsquare'
    }
    h2{
      padding-top: 25px;
      line-height: 0px;
      font-size: 12px;
      font-family: 'nanumsquare'
      color: black;
      opacity: 40%;
    }
    .btn_design{
      width: 100%;
      padding: 10px 10px;
      margin-top:50px;
      margin-bottom:20px;
      color: white;
      border:0;
      background: #424242;
      border-radius: 7px;
      font-size: 12px;
    }
  }
  @media only screen and (min-width:601px){
  h1 {
    font-size: 30px;
    font-family: 'nanumsquare'
  }
  h2{
    padding-top: 30px;
    line-height: 0px;
    font-size: 18px;
    font-family: 'nanumsquare'
    color: black;
    opacity: 40%;
  }
  .btn_design{
    width: 100%;
    padding: 15px 15px;
    margin-top:50px;
    margin-bottom:20px;
    color: white;
    border:0;
    background: #424242;
    border-radius: 7px;
    font-size: 20px;
  }
}
`;