import { Link } from 'react-router-dom';
import styled from 'styled-components';
  

const Login = () => {
  return (
    <Wrapper>
      <>
        <div className="text">
          <h1>OTTERLAND</h1>
          <h2>Otterland 함께 Cube의 오너가 되어</h2>
          <h2>다양한 체험들을 즐겨보세요!</h2>
          <div className="btn">
            <Link to={'/login'}>
              <h3>continue with kakao</h3>
              <button
                className="btn_design"
                onClick={event =>
                  (window.location.href = `${process.env.REACT_APP_API_URL}/auth/login/kakao/callback`)
                }
              >
                카카오 로그인
              </button>
            </Link>
          </div>
        </div>
      </>
    </Wrapper>
  );
};

export default Login;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  @media only screen and (max-width: 600px){
    .text{
      width: 100%;
    }
    h1 {
      font-size: 25px;
      font-family: 'aldrich';
      color: #FF5100;
    }
    h2{
      padding-top: 25px;
      line-height: 0px;
      font-size: 14px;
      font-family: 'nanumsquare'
      color: black;
      opacity: 40%;
    }
    h3{
      font-weight: 500;
    }
    .btn{
      font-size: 14px;
      padding-top: 30px;
    }
    .btn_design{
      width: 280px;
      padding: 12px 12px;
      font-size: 15px;
      margin-top:10px;
      font-family: 'nanumsquarebold';
      color: #3A1D1D;
      border: none;
      background: #F7E600;
      border-radius: 7px;
    }
    a {
      text-decoration: none;
      color: black;
    }
  }
  @media only screen and (min-width: 601px){
  .text{
    width: 100%;
  }
  h1 {
    font-size: 40px;
    font-family: 'aldrich';
    color: #FF5100;
  }
  h2{
    padding-top: 30px;
    line-height: 0px;
    font-size: 18px;
    font-family: 'nanumsquare'
    color: black;
    opacity: 40%;
  }
  h3{
    font-weight: 500;
  }
  .btn{
    font-size: 15px;
    padding-top:50px;
  }
  .btn_design{
    width: 350px;
    padding: 15px 15px;
    margin-top:10px;
    font-family: 'nanumsquarebold';
    font-size: 18px;
    color: #3A1D1D;
    border: none;
    background: #F7E600;
    border-radius: 7px;
  }
  a {
    text-decoration: none;
    color: black;
  }
}
`;
