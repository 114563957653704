import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import apiCode from '../lib/api/apiCode';
import { apiBundleList } from '../lib/api/apiCube';

const MyPage = () => {
  const navigate = useNavigate();

  const [Image] = useState(
    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
  );
  const [totalNum, setTotalNum] = useState(0);
  const [bundleList, setBundleList] = useState([]);

  // 구매한 큐브 번들 리스트 api 호출
  const apiCallBundleList = async page => {
    const data = await apiBundleList(page);
    // 세션이 끊어진 상태
    if (data.resultCode === apiCode.error.needLogin) {
      navigate('/login');
      return;
    }

    if (data.resultCode === apiCode.error.success) {
      setTotalNum(data.totalNum);
      setBundleList(data.bundleList);
    }
  };

  useEffect(() => {
    apiCallBundleList(1);
  }, []);

  return (
    <Container>
      <ContentBox0>
        <text>보유 큐브</text>
      </ContentBox0>
      {bundleList.map((item,index) => (
        <ContentBox key={index}>
          <text>
            {item.address}
            <br />
            {item.total_num}
            <br />
            <button>큐브 팔기</button>
          </text>
        </ContentBox>
      ))}
    </Container>
  );
};

export default MyPage;

const Container = styled.div`
  display: grid;
  width: 100%;
  hegith: auto;
  padding-bottom: 50px;
  margin-left: 25%;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 2%;
  font-family: 'nanumsquarebold';
`;
const ContentBox0 = styled.div`
  width: 100%;
  margin-bottom: -50px;
  display: flex;
  background: white;
  align-items: center;
  gap: 2%;
  .link {
    border: 2px solid none;
    border-radius: 10px;
    width: 50%;
    padding: 50px 50px;
    background: #ff51001a;
    font-size: 18px;
    text-decoration: none;
  }
  text {
    text-align: left;
    border-bottom: 2px solid lightgray;
    width: 50%;
    font-size: 35px;
  }
`;
const ContentBox = styled.div`    

width: 50%;
display: flex;
background: white;
align-items: center;
gap: 2%;
justify-content: space-around;
.link{
    border: 2px solid none;
    border-radius: 10px;
    width: 50%;
    padding: 50px 50px;
    background: #FF51001A;
    font-size: 18px;
    text-decoration: none;
}
text{
    border: 2px solid none;
    border-radius: 10px;
    width: 50%;
    padding: 50px 50px;
    background: #FF51001A;
    font-size: 18px;
}
button{
    border: 1px solid #FF5100;
    border-radius: 5px;
    background: none;
    padding 5px 15px 5px 15px;
    color: #FF5100;
    font-size: 18px;
}
`;
