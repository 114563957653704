import styled from 'styled-components';
import React, { useState, useRef, useEffect } from 'react';
import Avatar from 'react-avatar';
import apiCode from '../lib/api/apiCode';
import { apiMyInfo, apiUpdateMyInfo } from '../lib/api/apiMyPage';
import { Link, useNavigate } from 'react-router-dom';

const Signup = props => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [nickName, setNickName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [Image, setImage] = useState(
    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
  );

  // 내 정보 확인 api 호출
  const apiCallMyInfo = async () => {
    const data = await apiMyInfo();
    // 세션이 끊어진 상태
    if (data.resultCode === apiCode.error.needLogin) {
      navigate('/login');
      return;
    }

    if (data.resultCode === apiCode.error.success) {
      setEmail(data.email);
      setImage(data.avatarUrl);
      setNickName(data.nickName);
    }
  };

  const onChangeNickName = e => {
    setNickName(e.target.value);
  };

  // 전화 번호 수정
  const onChangePhoneNumber = e => {
    setPhoneNumber(e.target.value);
  };

  // 가입하기 버튼 클릭 => 내 정보 업데이트
  const handleSignUp = async () => {
    const result = await apiUpdateMyInfo(nickName, phoneNumber);
    // 세션이 끊어진 상태
    if (result.resultCode === apiCode.error.needLogin) {
      navigate('/login');
      return;
    }

    if (result.resultCode === apiCode.error.success) {
      navigate('/Signup_Success');
    }
  };

  const fileInput = useRef(null);
  const setFile = useRef('');
  const onChange = e => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    } else {
      //업로드 취소할 시
      setImage(
        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
      );
      return;
    }
    //화면에 프로필 사진 표시
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    apiCallMyInfo();
  }, []);

  return (
    <Wrapper>
      <div className="box">
        <h1>
          <span>OTTERLAND</span>에 오신것을 환영합니다
        </h1>
        <text>아래의 절차에 따라 회원가입을 진행해 주세요</text>
        <div className="email_text">
          이메일
        </div>
        <div className="email_box">
          {email}
        </div>
        <div className="profile">
          프로필 *<br />
          <Avatar
            className="img"
            src={Image}
            style={{ margin: '20px' }}
            size={100}
            onClick={() => {
              fileInput.current.click();
            }}
          />
          <input
            type="file"
            style={{ display: 'none' }}
            accept="image/jpg,impge/png,image/jpeg"
            name="profile_img"
            onChange={onChange}
            ref={fileInput}
          />
        </div>
        <div className="input_text">
          <text>
            닉네임 *<br />
          </text>
          <input
            className="input"
            placeholder="3~10자의 영어 소문자, 숫자, 한국어만 가능합니다."
            value={nickName}
            onChange={onChangeNickName}
          ></input>
          <button className="duple">
            확인
          </button>
        </div>
        <div className="input_text">
          <text>
            휴대폰 번호 *<br />
          </text>
          <input
            className="input"
            onChange={onChangePhoneNumber}
            value={phoneNumber}
          ></input>
          <button className="duple">
            발송
          </button>
        </div>
        <div className="input_text">
          <text>
            인증번호 *<br />
          </text>
          <input className="input"></input>
          <button className="duple">
            확인
          </button>
        </div>
        <div className="checktext">
          <text >
            회원가입 시 Otterland <Link to={'/service'}>서비스 약관</Link>, <Link to={'/privacy'}>개인정보 보호 정책</Link>에 동의한 것으로 간주됩니다.
          </text>
        </div>
        <div className="checkbox">
          <input
            type="checkbox"
            id="check"
          ></input>
          <label id="check" htmlFor="check"></label>
          <text> 마케팅 동의</text>
        </div>
        <div className="btn">
          <button className="btn_design" onClick={handleSignUp}>
            가입하기
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default Signup;

const Wrapper = styled.div`
display:block;
height: auto;
min-height: 100%;
padding-bottom: 280px;
@media only screen and (max-width:600px){
  .box{
        width: 140%;
        padding-top: 50px;
        background: white;
        margin-left: -20%;
        h1 {
          color: black;
          font-size: 20px;
          padding-bottom: 5px;
          span{
            color: #FF5100;
            font-size: 22px;
            font-family: 'Aldrich', sans-serif;
          }
      }
      .email_text{
        padding-top: 50px;
        font-size: 13px;
        font-family: 'nanumsquarebold';
        text-align: left;
        margin-left: 25%;
      }
      .email_box{
        margin-left: 25%;
        width: 50%;
        margin-top:10px;
        padding: 18px 18px;
        border:none;
        border-radius: 5px;
        background: lightgray;
        opacity: 40%;
      }
      text{
        font-size: 14px;
      }
      .profile{
        margin-left: 25%;
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 13px;
        font-family: 'nanumsquarebold';
        text-align: left;
      }
      .img{
        border-radius: 100px;
      }
      .input_text{
        margin-left: 25%;
        margin-bottom: 20px;
        font-size: 13px;
        font-family: 'nanumsquarebold';
        text-align: left;
      }
      .input{
        text-align: center;
        width: 55%;
        margin-top:10px;
        margin-right: 30px;
        padding: 8px 8px;
        border: 2px solid lightgray;
        border-radius: 5px;
        opacity: 40%;
      }
      .duple{
        width: 50px;
        padding: 10px 10px;
        color: white;
        border:0;
        background: #f55100;
        border-radius: 7px;
      
      }
      .checktext{
        width: 50%;
        margin-top:10px;
        margin-left: 25%;
        text-align: left;
      }
      .checkbox{
        margin-top:10px;
        margin-left: 25%;
        text-align: left;
      }
      .btn{
        text-align: right;
        margin-right: 25%;
      }
      .btn_design{
        padding: 10px 30px 10px 30px;
        margin-top:50px;
        margin-bottom:20px;
        color: white;
        border:0;
        background: #424242;
        border-radius: 7px;
        text-align: right;
      }
    }
  }
@media only screen and (min-width: 601px) and (max-width:768px){
  .box{
        width: 100%;
        padding-top: 50px;
        background: white;
        h1 {
          color: black;
          font-size: 20px;
          padding-bottom: 5px;
          span{
            color: #FF5100;
            font-size: 22px;
            font-family: 'Aldrich', sans-serif;
          }
      }
      .email_text{
        padding-top: 50px;
        font-size: 13px;
        font-family: 'nanumsquarebold';
        text-align: left;
        margin-left: 25%;
      }
      .email_box{
        margin-left: 25%;
        width: 50%;
        margin-top:10px;
        padding: 18px 18px;
        border:none;
        border-radius: 5px;
        background: lightgray;
        opacity: 40%;
      }
      text{
        font-size: 14px;
      }
      .profile{
        margin-left: 25%;
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 13px;
        font-family: 'nanumsquarebold';
        text-align: left;
      }
      .img{
        border-radius: 100px;
      }
      .input_text{
        margin-left: 25%;
        margin-bottom: 20px;
        font-size: 13px;
        font-family: 'nanumsquarebold';
        text-align: left;
      }
      .input{
        text-align: center;
        width: 55%;
        margin-top:10px;
        margin-right: 30px;
        padding: 8px 8px;
        border: 2px solid lightgray;
        border-radius: 5px;
        opacity: 40%;
      }
      .duple{
        width: 50px;
        padding: 10px 10px;
        color: white;
        border:0;
        background: #f55100;
        border-radius: 7px;
      
      }
      .checktext{
        width: 40%;
        margin-top:10px;
        margin-left: 25%;
        text-align: left;
      }
      .checkbox{
        margin-top:10px;
        margin-left: 25%;
        text-align: left;
      }
      .btn{
        text-align: right;
        margin-right: 25%;
      }
      .btn_design{
        padding: 10px 30px 10px 30px;
        margin-top:50px;
        margin-bottom:20px;
        color: white;
        border:0;
        background: #424242;
        border-radius: 7px;
        text-align: right;
      }
    }
  }
@media only screen and (min-width: 769px) and (max-width:1199px){
  .box{
    margin-left: 15%;
        width: 70%;
        padding-top: 50px;
        background: white;
        h1 {
          color: black;
          font-size: 20px;
          padding-bottom: 5px;
          span{
            color: #FF5100;
            font-size: 22px;
            font-family: 'Aldrich', sans-serif;
          }
      }
      .email_text{
        padding-top: 50px;
        font-size: 13px;
        font-family: 'nanumsquarebold';
        text-align: left;
        margin-left: 25%;
      }
      .email_box{
        margin-left: 25%;
        width: 50%;
        margin-top:10px;
        padding: 18px 18px;
        border:none;
        border-radius: 5px;
        background: lightgray;
        opacity: 40%;
      }
      text{
        font-size: 14px;
      }
      .profile{
        margin-left: 25%;
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 13px;
        font-family: 'nanumsquarebold';
        text-align: left;
      }
      .img{
        border-radius: 100px;
      }
      .input_text{
        margin-left: 25%;
        margin-bottom: 20px;
        font-size: 13px;
        font-family: 'nanumsquarebold';
        text-align: left;
      }
      .input{
        text-align: center;
        width: 55%;
        margin-top:10px;
        margin-right: 30px;
        padding: 8px 8px;
        border: 2px solid lightgray;
        border-radius: 5px;
        opacity: 40%;
      }
      .duple{
        width: 50px;
        padding: 10px 10px;
        color: white;
        border:0;
        background: #f55100;
        border-radius: 7px;
      
      }
      .checktext{
        width: 40%;
        margin-top:10px;
        margin-left: 25%;
        text-align: left;
      }
      .checkbox{
        margin-top:10px;
        margin-left: 25%;
        text-align: left;
      }
      .btn{
        text-align: right;
        margin-right: 25%;
      }
      .btn_design{
        padding: 10px 30px 10px 30px;
        margin-top:50px;
        margin-bottom:20px;
        color: white;
        border:0;
        background: #424242;
        border-radius: 7px;
        text-align: right;
      }
    }
  }
@media only screen and (min-width: 1200px){
.box{
  margin-left: 20%;
      width: 60%;
      padding-top: 50px;
      background: white;
      h1 {
        color: black;
        font-size: 20px;
        padding-bottom: 5px;
        span{
          color: #FF5100;
          font-size: 22px;
          font-family: 'Aldrich', sans-serif;
        }
    }
    .email_text{
      padding-top: 50px;
      font-size: 13px;
      font-family: 'nanumsquarebold';
      text-align: left;
      margin-left: 25%;
    }
    .email_box{
      margin-left: 25%;
      width: 50%;
      margin-top:10px;
      padding: 18px 18px;
      border:none;
      border-radius: 5px;
      background: lightgray;
      opacity: 40%;
    }
    text{
      font-size: 14px;
    }
    .profile{
      margin-left: 25%;
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 13px;
      font-family: 'nanumsquarebold';
      text-align: left;
    }
    .img{
      border-radius: 100px;
    }
    .input_text{
      margin-left: 25%;
      margin-bottom: 20px;
      font-size: 13px;
      font-family: 'nanumsquarebold';
      text-align: left;
    }
    .input{
      text-align: center;
      width: 55%;
      margin-top:10px;
      margin-right: 30px;
      padding: 8px 8px;
      border: 2px solid lightgray;
      border-radius: 5px;
      opacity: 40%;
    }
  
    .duple{
      width: 50px;
      padding: 10px 10px;
      color: white;
      border:0;
      background: #f55100;
      border-radius: 7px;
    
    }
    .checktext{
      width: 40%;
      margin-top:10px;
      margin-left: 25%;
      text-align: left;
    }
    .checkbox{
      margin-top:10px;
      margin-left: 25%;
      text-align: left;
    }
    .btn{
      text-align: right;
      margin-right: 25%;
    }
    .btn_design{
      padding: 10px 30px 10px 30px;
      margin-top:50px;
      margin-bottom:20px;
      color: white;
      border:0;
      background: #424242;
      border-radius: 7px;
      text-align: right;
    }
  }
}
`;
