import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import apiCode from '../lib/api/apiCode';
import { apiMyPoint, apiPointCharge } from '../lib/api/apiWallet';

//결제가 성공하면 success, 실패하면 fail로 이동했으면 좋겠습니다.
const Buy_Info = () => {
  const navigate = useNavigate();

  const [cost, setNumber] = useState(0);
  const [myPoint, setMyPoint] = useState(0);
  const [addPoint, setPoint] = useState(0);

  // 내 포인트 조회 api 호출
  const apiCallMyPoint = async () => {
    const data = await apiMyPoint();
    // 세션이 끊어진 상태
    if (data.resultCode === apiCode.error.needLogin) {
      navigate('/login');
      return;
    }

    if (data.resultCode === apiCode.error.success) {
      setMyPoint(data.point);
    }
  };

  // 포인트 구매하기 버튼 클릭
  const handlePurchase = async () => {
    const data = await apiPointCharge(addPoint);
    // 세션이 끊어진 상태
    if (data.resultCode === apiCode.error.needLogin) {
      navigate('/wallet/pointcharge');
      return;
    }

    if (data.resultCode === apiCode.error.success) {
      navigate('/mypage');
    }
    else{
      navigate('/fail');
    }
  };


  useEffect(() => {
    apiCallMyPoint();
  }, []);

  
  useEffect(() => {
    apiCallMyPoint();
  }, []);
  return (
    <Wrapper>
      <ul className="info">
        <li>
          <h1 className="point">포인트 충전</h1>
        </li>
      </ul>

      <ul className="info2">
        <li>
          <text >충전 포인트</text>
        </li>
        <li>
          <text>{setPoint} P</text>
        </li>
      </ul>

      <ul className="info3">
        <li>
          <text >결제 방법</text>
        </li>
        <li>
          <text> PG사</text>
        </li>
      </ul>

      <ul className="menu">
        <li>
          <Link to={'/point'}>
            <button className="buy_btn">뒤로가기</button>
          </Link>
        </li>
        <li>
          <button className="buy_btn2" onClick={handlePurchase}>
            구매하기
          </button>
        </li>
      </ul>
    </Wrapper>
  );
};
export default Buy_Info;
const Wrapper = styled.div`
text-decoration: none;
display: block;
height: auto;
min-height: 100%;
padding-bottom: 250px;
font-family: 'nanumsquare';
@media only screen and (max-width:600px){
  ul{
    width: 70%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 15%;
  }
  li+li{
  }
  text{
    font-weight: 600;
    font-size: 14px;
  }
  .point{
    margin-top: 80px;
  }
  h1{
    font-size: 20px;
    margin-top: 20px;
  }
  .info{
    margin-top: 100px;
    padding-bottom:10px;
    border-bottom: 1px solid lightgray;
  }
  .info2{
    margin-top: 60px;
    padding: 15px 15px;
    border: 1px solid none;
    border-radius: 8px;
    background:#F5F5F5;
  }
  .info3{
    margin-top: 60px;
    padding: 15px 15px;
    padding-bottom:10px;
  }
  .menu{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .buy_btn{
    font-size: 12px;
    background: none;
    padding: 8px 15px 8px 15px;
    border-radius: 8px;
  }
  .buy_btn2{
    font-size: 12px;
    background: #ff5100;
    color: white;
    border: none;
    padding: 10px 15px 10px 15px;
    border-radius: 8px;
   }
  }
@media only screen and (min-width: 601px) and (max-width:768px){
  ul{
    width: 70%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 15%;
  }
  li+li{
  }
  text{
    font-weight: 600;
    font-size: 15px;
  }
  .point{
    margin-top: 80px;
  }
  h1{
    font-size: 25px;
    margin-top: 20px;
  }
  .info{
    margin-top: 100px;
    padding-bottom:10px;
    border-bottom: 1px solid lightgray;
  }
  .info2{
    margin-top: 80px;
    padding: 15px 15px;
    border: 1px solid none;
    border-radius: 8px;
    background:#F5F5F5;
  }
  .info3{
    margin-top: 80px;
    padding: 15px 15px;
    padding-bottom:10px;
  }
  .menu{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .buy_btn{
    font-size: 16px;
    background: none;
    padding: 10px 30px 10px 30px;
    border-radius: 8px;
  }
  .buy_btn2{
    font-size: 16px;
    background: #ff5100;
    color: white;
    border: none;
    padding: 12px 30px 12px 30px;
    border-radius: 8px;
   }
  }
@media only screen and (min-width: 769px) and (max-width:1199px){
  ul{
    width: 70%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 15%;
  }
  li+li{
  }
  text{
    font-weight: 600;
    font-size: 20px;
  }
  .point{
    margin-top: 80px;
  }
  h1{
    font-size: 30px;
    margin-top: 20px;
  }
  .info{
    margin-top: 100px;
    padding-bottom:10px;
    border-bottom: 1px solid lightgray;
  }
  .info2{
    margin-top: 100px;
    padding: 15px 15px;
    border: 1px solid none;
    border-radius: 8px;
    background:#F5F5F5;
  }
  .info3{
    margin-top: 100px;
    padding: 15px 15px;
    padding-bottom:10px;
  }
  .menu{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .buy_btn{
    font-size: 20px;
    background: none;
    padding: 10px 30px 10px 30px;
    border-radius: 8px;
  }
  .buy_btn2{
    font-size: 20px;
    background: #ff5100;
    color: white;
    border: none;
    padding: 10px 30px 10px 30px;
    border-radius: 8px;
   }
  }
@media only screen and (min-width: 1200px){
ul{
  width: 70%;
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin-left: 15%;
}
li+li{
}
text{
  font-weight: 600;
  font-size: 25px;
}
.point{
  margin-top: 80px;
}
h1{
  font-size: 40px;
  margin-top: 20px;
}
.info{
  margin-top: 100px;
  padding-bottom:10px;
  border-bottom: 1px solid lightgray;
}
.info2{
  margin-top: 100px;
  padding: 15px 15px;
  border: 1px solid none;
  border-radius: 8px;
  background:#F5F5F5;
}
.info3{
  margin-top: 100px;
  padding: 15px 15px;
  padding-bottom:10px;
}
.menu{
  padding-top: 50px;
  padding-bottom: 50px;
}
.buy_btn{
  font-size: 25px;
  background: none;
  padding: 10px 30px 10px 30px;
  border-radius: 8px;
}
.buy_btn2{
  font-size: 25px;
  background: #ff5100;
  color: white;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 8px;
 }
}
`;
