import styled from "styled-components";


const Service = () => {
  return (
    <Wrapper>
      <h1>
        오터랜드(Otterland) 서비스 이용 약관
      </h1>
      <div className="text"><h2>제 1 장 총칙</h2><br />
        <h3>제 1 조 (목적)</h3><br />
        본 서비스 이용 약관(이하 ‘약관’이라 함)은 주식회사 루몽스튜디오 (이하 '회사'라
        함)가 제공하는 오터랜드(Otter land) 서비스(이하 ‘서비스’)를 이용함에 있어, ‘회
        사’와 ‘회원’(제 2조에서 정의합니다)의 권리, 의무, 책임사항 및 ‘회원’의 ‘서비스 이
        용 절차’에 관한 사항을 규정함을 목적으로 합니다. ‘서비스’의 제공 방식에 따라 별
        도로 회원가입을 했더라도 ‘회원’은 ‘회사’에서 운영하는 ‘서비스’에 추가 약관 동의
        없이 이용할 수 있습니다.<br /><br />
        <h3>제 2 조 (용어의 정의)</h3><br />
        본 ‘약관’에서 사용되는 용어의 정의는 다음과 같습니다.<br /><br />
        1. 서비스 : ‘회원’이 ‘큐브’ ‘사용권’ 구매, ‘액티비티’ 참여, ‘포인트’ 획득 및 사용 등
        의 행위를 할 수 있는 메타버스 플랫폼으로서, 구현되는 단말기 종류(PC, 휴대형
        단말기 등의 각종 유무선장치 포함)와 상관없이 ‘회사’에 종속되어 ‘회원’을 대상으
        로 제공하는 모든 기능을 의미합니다.<br /><br />
        ① 상점 : ‘서비스’를 통해 정보를 제공하는 곳을 의미합니다.<br /><br />
        ② 가맹점 : ‘가맹점’이란 ‘서비스’를 통해 ‘회원’들이 ‘포인트’ 혹은 ‘가맹점 포인
        트’를 사용할 수 있는 ‘상점’을 의미합니다.<br /><br />
        ③ 게시물 : ‘회사’, ‘상점’, ‘가맹점’이 ‘서비스’에 게시하거나, ‘회원’이 ‘서비스’에서
        ‘상점’과 ‘가맹점’에 직접 남긴 리뷰, 평가 점수, 글, 그림, 사진, 이미지, 음성, 음향,
        동영상 등을 의미합니다.<br /><br />
        ④ 큐브 : ‘회원’이 ‘사용권’을 구매할 수 있는 ‘서비스’에 종속된 디지털 공간을 의미
        합니다.<br /><br />
        ⑤ 포인트 : ‘회원’이 ‘서비스’ 내 ‘액티비티’를 통해 ‘회사’로부터 획득하여 ‘서비스’
        및 ‘가맹점’에서 결제대금 지급을 위해 사용할 수 있는 것을 의미합니다.<br /><br />
        ⑥ 가맹점 포인트 : ’회원’이 ‘서비스’ 내 ‘액티비티’를 통해 ‘회사’와 제휴한 ‘가맹
        점’으로부터 획득하여 ‘가맹점’에서 결제대금의 지급을 위해 사용할 수 있는 것을
        의미합니다.<br /><br />
        ⑦ 액티비티 : ‘서비스’ 내에서 행해지는 ‘회원’의 참여 행위 일체를 의미합니다.<br /><br />
        ⑧ 사용권 : ‘큐브’의 사용 권한에 대해서는 다음과 같이 정의합니다.<br /><br />
        (1) '회사’는 ‘큐브’ ‘사용권’을 구매한 ‘회원’ 1인에게 한하여 ‘큐브’ ‘사용권’에 대한
        배타적인 권한을 부여한다.<br /><br />
        (2) ‘회사’는 ‘큐브’의 ‘사용권’을 구매한 ‘회원’에게 ‘큐브’ ‘사용권’을 보유함으로써
        행사 가능한 행위에 대한 독점적인 권한을 부여한다.<br /><br />
        ⑨ 사용 : ‘큐브’의 ‘사용’은 다음과 같이 정의합니다.<br /><br />
        (1) ‘회원’이 ‘큐브’의 ‘사용권’을 구매하고자 대가를 지불한 뒤, ‘서비스’에 접속하여
        자신의 계정에서 ‘큐브’의 ‘사용권’ 구매 사실을 확인하는 행위<br /><br />
        (2) ‘회원’이 구매 혹은 구매 약정을 통하여 ‘사용권’을 획득한 ‘큐브’을 기반으로 ‘액
        티비티’를 실행하거나 실행할 의사를 밝히며, ‘큐브’의 ‘사용권’을 보유한 상태를 타
        인에게 고지하는 행위<br /><br />
        (3) ‘회원’이 자신이 획득한 ‘큐브’ ‘사용권’의 보유 사실을 공개 매체에 게시하는 행
        위<br /><br />
        2. 회원 : 본 ‘약관’에 동의하여 이용계약을 체결하고 ‘서비스’를 이용하는 모든 회
        원을 의미합니다.<br /><br />
        3. 비회원 : ‘회원’으로 가입하지 않고 ‘회사’가 제공하는 ‘서비스’를 이용하는 자를
        의미합니다.<br /><br />
        4. 개인 정보 : 정보 통신망 이용 촉진 및 정보 보호 등에 관한 법률에서 언급하는
        개인 정보(휴대폰 번호, 이메일 주소 등)을 의미합니다.<br /><br />
        5. 본 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 사항은 관계 법령에서
        정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.<br /><br />
        <h3>제 3 조 (서비스 사업자의 개요)</h3><br />
        1. 이 ‘약관’에서 규정하는 서비스는 ‘회사’가 제공하는 ‘서비스’에 한하며, 상호 및
        주소, 연락처는 다음의 각 호와 같습니다.<br /><br />
        2. 상호 : 주식회사 루몽스튜디오<br /><br />
        3. 주소 : 대구광역시 동구 동대구로 469.201호(대구콘텐츠비즈니스센터)<br /><br />
        4. 연락처 : 1234-4567<br /><br />
        <h3>제 4 조 (약관의 효력 및 변경)</h3><br />
        1. 이 ‘약관’은 ‘회사’에서 제공하는 ‘서비스’를 이용하고자 하는 모든 이용자에 대하
        여 그 효력이 발생합니다.<br /><br />
        2. '회사'는 이 ‘약관’의 내용을 '회원'이 쉽게 알 수 있도록 '회사'의 웹사이트 화면
        하단에 상설 메뉴로 게시합니다.<br /><br />
        3. '회사'는 대한민국의 법령과 국제조약을 위배하지 않는 범위에서 이 ‘약관’을 개
        정할 수 있습니다.<br /><br />
        4. '회사'가 약관을 개정할 경우에는 적용 예정일 및 개정 사유를 명시하여 현행 약
        관과 함께 적용 예정일 7일 전부터 공지합니다. 다만, '회원'에게 불리하게 약관 내
        용을 변경하는 경우에는, 최소 30일 이상의 사전 유예 기간을 두고 공지하거나 전
        자 우편 발송 등 전자적 수단을 통해 별도로 통지합니다.<br /><br />
        5. '회원'은 개정된 약관에 동의하지 않을 권리가 있으며, 개정된 약관에 동의하지
        않을 경우 이용계약을 해지할 수 있습니다.<br /><br />
        6. '회사'가 본 조 제4항 단서에 따라 회원에게 통지하면서, 상당한 기간(예: 30일)
        을 정하여 거부 의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고
        지하였음에도 '회원'의 의사 표시가 없는 경우에는 변경된 약관을 승인한 것으로
        봅니다.<br /><br />
        <h3>제 5 조 (개별 서비스에 대한 약관 및 해석)</h3><br />
        1. ‘회사’는 ‘서비스’에 관하여 적용될 사항(이하 ‘개별약관’) 및 세부적인 내용(이하
        ’이용정책’)을 정하여 운영할 수 있으며 해당 내용은 ‘회사’ 홈페이지 및 개별 서비
        스를 통해 공지합니다.<br /><br />
        2. 개별 서비스에 대한 ‘개별약관’이 본 약관과 상충할 경우에는 ‘개별약관’이 우선
        하여 적용됩니다.<br /><br />
        3. ‘회원’은 항상 ‘개별약관’이나 ‘이용정책’의 내용에 변경이 있는지를 주시하여야
        하며, 변경사항의 공지가 있을 시에는 이를 확인하여야 합니다.<br /><br />
        <h2>제 2 장 서비스 이용계약 체결</h2><br />
        <h3>제 6 조 (이용계약의 체결)</h3><br />
        1. 이용계약은 ‘회원’이 되고자 하는 자(이하 ‘가입신청자’)가 약관의 내용에 대하여
        동의를 한 다음 ‘서비스’ 이용 신청을 하고, ‘회사’가 이러한 신청에 대하여 승낙함
        으로써 체결됩니다.<br /><br />
        2. ‘회사’는 ‘가입신청자’의 신청에 대하여 ‘서비스’ 이용을 승낙함을 원칙으로 합니
        다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후
        에 이용계약을 해지할 수 있습니다.<br /><br />
        ① ‘가입신청자’가 이 약관에 의하여 이전에 회원 자격을 상실한 적이 있는 경우
        (단, ‘회사’의 ‘회원’ 재가입 승낙을 얻은 경우에는 예외로 함).<br /><br />
        ② 실명이 아니거나 타인의 명의를 이용한 경우.<br /><br />
        ③ 허위의 정보를 기재하거나, ‘회사’가 제시하는 내용을 기재하지 않은 경우.<br /><br />
        ④ 부정한 용도로 ‘서비스’를 사용하고자 하는 경우.<br /><br />
        ⑤ 14세 미만 미성년자가 법정대리인(부모 등)의 동의 없이 신청하는 경우.<br /><br />
        ⑥ ‘회사’로부터 이용정지 및 제한을 당한 ‘회원’이 그 이용정지 기간 중에 이용계약
        을 임의 해지하고 재신청을 하는 경우.<br /><br />
        ⑦ ‘가입신청자’의 귀책사유로 인하여 승인이 불가능하거나 기타 ‘회사’가 정한 제
        반 사항을 위반하여 신청하는 경우.<br /><br />
        ⑧ ‘회사’의 정책에 적합하지 않는 ‘회원’으로 판단되는 경우나 ‘서비스’ 제공이 곤
        란한 경우.<br /><br />
        ⑨ ‘회원’의 ‘서비스’ 이용 목적이나 ‘서비스’ 이용 방법이 ‘회사’의 재산권이나 영업
        권을 침해하거나 침해할 우려가 있는 경우.<br /><br />
        ⑩ 사회의 안녕, 질서 또는 미풍양속을 저해할 목적으로 신청한 경우.<br /><br />
        ⑪ 회사의 내부 ‘서비스’ 기준에 적합하지 않은 ‘회원’으로 판단되는 경우나 ‘서비
        스’ 제공이 곤란한 경우<br /><br />
        ⑫ 기타 ‘회사’가 합리적인 판단에 의하여 필요하다고 인정하는 경우.<br /><br />
        3. 본 조 제1항에 따른 신청에 있어 ‘회사’는 전문기관을 통한 실명확인 및 본인인
        증을 요청할 수 있습니다.<br /><br />
        4. 본 조 제1항에 따른 신청에 있어 ‘회사’는 제공하는 ‘서비스’의 종류와 내용에 따
        라 필요한 경우, ‘가입신청자’에게 추가 정보를 요청할 수 있습니다.<br /><br />
        5. ‘회사’는 ‘서비스’ 관련 설비의 여유가 없거나, 기술 상 또는 업무 상 문제가 있는
        경우에는 승낙을 유보할 수 있습니다.<br /><br />
        6. 본 조 제2항과 제5항에 따라 회원가입 신청의 승낙을 하지 아니하거나 유보한
        경우, ‘회사’는 원칙적으로 이를 ‘가입신청자’에게 알리도록 합니다.<br /><br />
        7. 이용계약의 성립 시기는 ‘회사’가 가입 완료를 신청 절차 상에서 표시하거나 ‘회
        사’가 정하는 방법으로 ‘회사’가 ‘가입신청자’에게 가입 완료 안내를 발송하여 도달
        한 시점으로 합니다.<br /><br />
        <h3>제 7 조 (이용계약의 해지 및 이용제한)</h3><br />
        1. ‘회원’은 언제든지 ‘회사’가 정한 절차에 따라 회원 탈퇴를 요청하여 이용계약 해
        지 신청을 할 수 있으며, ‘회사’는 관련법 등이 정하는 바에 따라 이를 즉시 처리합
        니다.<br /><br />
        2. ‘회원’이 계약을 해지할 경우, 회원정보는 관련법 및 개인정보취급방침에 따라
        처리됩니다.<br /><br />
        3. ‘회사’는 ‘회원’에게 아래 각 호의 사유가 발생하는 경우, 해당 ‘회원’의 ‘서비스’
        이용을 중단하거나 경고, 일시 이용 정지, 영구 이용 정지 등으로 ‘서비스’ 이용을
        단계적으로 제한할 수 있습니다.<br /><br />
        ① 부정하게 타인의 계정을 사용하여 ‘서비스’를 이용한 경우.<br /><br />
        ② ‘회사’ 서비스의 운영을 고의 및 과실로 방해하는 경우.<br /><br />
        ③ 제6조 제2항에 따른 ‘서비스’ 이용 승낙 거부 사유가 있음이 확인된 경우.<br /><br />
        ④ 타인의 ’서비스’ 이용을 방해하거나 정보를 도용하는 등 전자상거래 질서를 위
        협하는 경우.<br /><br />
        ⑤ ‘회사’를 이용하여 법령과 이 ‘약관’이 금지하거나 공공질서, 미풍양속에 반하는
        행위를 하는 경우.<br /><br />
        ⑥ 기타 ‘회사’가 ‘회원’의 이용 정지를 진행할 합리적인 사유가 있는 경우.<br /><br />
        ⑦ 타인의 의사에 반하여 광고성 정보를 전송하는 행위를 하는 경우.<br /><br />
        ⑧ ‘상점’이나 ‘가맹점’에 대해 고의적으로 악성 ‘게시물’을 남기는 행위를 하는 경
        우.<br /><br />
        ⑨ ‘상점’이나 ‘가맹점’에 대해 고의적으로 허위 정보를 등록하는 행위를 하는 경우.<br /><br />
        4. ‘회사’는 본 조 3항의 귀책사유로 ‘회원’의 ‘서비스’ 이용을 중단 및 제한하는 경
        우 이메일, 전화 또는 기타의 방법을 통하여 해지 의사를 통지하며, '회사'는 이용
        제한 내용에 해당하는 '회원'이 다시 이용 신청을 하는 경우 30일 동안 승낙을 제한
        하거나 이용 신청을 거절할 수 있습니다.<br /><br />
        5. 이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 ‘회원’이 책
        임을 부담하며, ‘회사’는 일체의 책임을 부담하지 않습니다.<br /><br />
        6. ‘회사’는 ‘회원’이 계속해서 6개월 이상 로그인하지 않는 경우, 회원정보의 보호
        및 운영의 효율성을 위해 이용을 정지할 수 있습니다.<br /><br />
        7. ‘회원’이 계약을 해지하는 경우, ‘회원’이 본인 계정으로 등록한 ‘게시물’ 일체는
        삭제될 수 있습니다. 다만, 회사는 ‘회원’이 작성한 ‘게시물’ 중 타인에 의해 스크랩
        되거나 공용 게시판에 등록된 경우 ‘회원’의 계약 해지에 의한 삭제를 보장하지 않
        습니다.<br /><br />
        8. ‘회원’은 본 조에 따른 이용 제한 등에 대해 ‘회사’가 정한 절차에 따라 이의신청
        을 할 수 있으며, 이의가 정당하다고 ‘회사’가 인정하는 경우 ‘회사’는 즉시 ‘서비
        스’의 이용을 재개합니다.<br /><br />
        <h2>제 3 장 서비스 이용</h2><br />
        <h3>제 8 조 (서비스의 내용 및 제공)</h3><br />
        1. ‘회사’가 본 약관에 따라 ‘회원’에게 제공하는 ‘서비스’는 아래 각 호와 같습니다.<br /><br />
        ① ‘회사’에서 큐레이션한 추천 ‘상점’ 및 ‘가맹점’ 정보 제공<br /><br />
        ② ‘상점’ 및 ‘가맹점’에 대해 소개, 지도 및 위치 정보, 메뉴 검색 등 상세 정보 서비
        스 제공<br /><br />
        ③ ‘상점’ 및 ‘가맹점’ 사용자 즐겨찾기 서비스 제공<br /><br />
        ④ ‘회사’에서 직접 수집한 전국 지역 쿠폰 서비스 제공<br /><br />
        ⑤ ‘가맹점’에 대한 ‘포인트’ 이용 서비스 제공<br /><br />
        ⑥ ‘큐브’ ‘사용권’ 구매 서비스 제공<br /><br />
        ⑦ ‘상점’ 및 ‘가맹점’에 대한 ‘액티비티’ 참여 및 이에 관련된 제반 활동<br /><br />
        ⑧ ‘회원’의 ‘포인트’ 획득 서비스 제공<br /><br />
        ⑨ ‘회원’ 간의 커뮤니티 서비스 제공<br /><br />
        ⑩ ‘상점’ 및 ‘가맹점’ 결제 서비스 제공<br /><br />
        ⑪ ‘상점’ 및 ‘가맹점’ 예약 서비스 및 기타 부가 서비스 제공<br /><br />
        ⑫ ‘큐브’ 공간 개발 서비스 제공<br /><br />
        ⑬ ‘상점’ 및 ‘가맹점’의 온라인 커머스 서비스 제공<br /><br />
        ⑭ ‘상점’ 및 ‘가맹점’ 소유주(소상공인) 대상 마케팅 및 지원 서비스, 기타 부가 서
        비스 제공<br /><br />
        ⑮ 3D 공간, VR, AR, 아바타, 플레이 공간 등 메타버스 서비스 제공<br /><br />
        2. ‘회사’는 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해 ‘회원’에게 제공하
        는 일체의 서비스를 제공할 수 있습니다.<br /><br />
        3. ‘회사’는 ‘서비스’를 연중무휴, 1일 24시간 제공함을 원칙으로 하나 운영 상 필요
        하거나 기타 정상적인 서비스를 위하여 불가피하다고 판단되는 경우 ‘서비스’ 이용
        시간을 제한할 수 있습니다.<br /><br />
        4. ‘회사’는 ‘서비스’의 제공에 필요한 경우 정기 점검을 실시할 수 있으며, 정기점
        검 시간은 홈페이지에서 공지한 바에 따릅니다. 정기 점검 시간은 사전에 공지하는
        것을 원칙으로 하나 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.<br /><br />
        5. ‘회사’는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영
        상 상당한 이유가 있는 경우 ‘서비스’의 제공을 일시적으로 중단할 수 있습니다. 이
        경우 ‘회사’는 공지사항, 푸시 메시지를 통해 ‘회원’에게 통지합니다. 다만, ‘회사’가
        사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.<br /><br />
        6. ‘회사’는 상당한 이유가 있는 경우에 운영 상, 기술 상의 필요에 따라 제공하고
        있는 전부 또는 일부 ‘서비스’를 변경할 수 있습니다.<br /><br />
        7. ‘회사’는 제공되는 ‘서비스’의 일부 또는 전부를 회사의 정책 및 운영의 필요 상
        수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 대한 특별한 규정이 없는 한
        ‘회원’에게 별도의 보상을 하지 않습니다.<br /><br />
        <h3>제 9 조 (서비스의 요금)</h3><br />
        1. '회사'가 제공하는 ‘서비스’는 기본적으로 무료입니다. 단, 유료 서비스의 경우 해
        당 서비스에 명시된 요금을 지불하여야 사용 가능합니다.<br /><br />
        2. 무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며 가입한 각 이동통신사
        의 정책에 따릅니다.<br /><br />
        <h3>제 10 조 (사용 가능한 결제 수단)</h3><br />
        1. ‘회원’은 ‘서비스’ 내 유료 서비스에 대한 구매대금의 지급을 다음 각 호의 하나
        로 할 수 있습니다 (일부의 경우 다수를 결합하여 결제 가능).<br /><br />
        ① 계좌이체<br /><br />
        ② 직불카드, 신용카드 등의 카드 결제<br /><br />
        ③ 그 외 다양한 결제 수단<br /><br />
        ④ ‘회사’가 지급한 ‘포인트’에 의한 결제. 단, ‘포인트’로 결제 가능한 서비스는 제
        20조에 따릅니다.<br /><br />
        2. ‘회원’이 구매대금의 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생
        한 책임과 불이익은 전적으로 ‘회원’이 부담합니다.<br /><br />
        <h3>제 11 조 (정보의 제공 및 광고의 게재)</h3><br />
        1. '회사'는 광고를 포함하여 다양한 정보를 공지사항, 전자우편, 핸드폰 문자메시
        지(SMS, LMS, MMS), 푸쉬 메시지, 메신저 어플리케이션 서비스 등의 방법으로
        '회원'에게 제공할 수 있습니다.<br /><br />
        2. '회사'는 불특정 다수 '회원'에 대한 통지를 하는 경우, 7일 이상 '서비스' 초기 화
        면에 동 통지를 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, '회원'에게 중
        대한 영향을 미치는 사항에 대하여는 문자메시지, 전자우편, 쪽지 발송 등으로 통
        지합니다.<br /><br />
        3. '회사'는 '서비스'의 운용과 관련하여, ‘서비스’ 화면 등에 대하여 '회사'가 지정하
        는 위치에 광고 등을 게재할 수 있습니다.<br /><br />
        4. '회원'은 '회사'에서 제공하는 사이트 광고에 대한 임의의 삭제, 비방, 기타 사이
        트 광고 방해 행위 등을 할 수 없습니다.<br /><br />
        5. '회사'는 '서비스' 상에 게재되어 있거나 '서비스'를 통한 광고주의 판촉 활동에
        '회원'이 참여하거나 교신 또는 거래함으로써 발생하는 손실과 손해에 대해 일체의
        책임을 지지 않습니다.<br /><br />
        <h3>제 12 조 (회원정보, 게시물에 대한 권리와 책임)</h3><br />
        1. '회원'이 '서비스'에 게시한 회원정보, 글 및 사진 등 모든 ‘게시물’에 대한 권리는
        '회사'에게 있습니다.<br /><br />
        2. '회사'가 작성한 저작물에 대한 저작권 및 기타 지적재산권은 '회사'에 귀속합니
        다.<br /><br />
        3. '회원'은 '회사'가 정하는 방법에 의해 다른 '회원'의 회원정보 및 ‘게시물’을 열람
        할 수 있습니다. 다만, 정당한 권한 없이 다른 '회원'의 회원정보 및 ‘게시물’을 제거,
        변경, 삭제하거나 게시, 전송, 링크, 배포하거나 저작자의 실명 또는 닉네임을 변경
        또는 은닉하거나 '회원'의 회원정보나 ‘게시물’의 제호를 변경하여 당사자의 허락
        없이 게시 및 게재할 수 없습니다.<br /><br />
        4. ‘회원’은 ‘회사’가 정하는 방법에 의해 ‘상점’, ‘가맹점’, 기타 제휴사 및 제3자가
        ‘서비스’ 내 게시한 ‘게시물’을 열람할 수 있습니다. 다만, 정당한 권한 없이 ‘게시
        물’을 제거, 변경, 삭제하거나, 게시, 전송, 링크, 배포하거나, 저작자의 실명 또는
        닉네임을 변경 또는 은닉하거나, ‘게시물’의 제호를 변경하여 당사자의 허락 없이
        게시 및 게재할 수 없습니다.<br /><br />
        5. '회원'은 '서비스'를 이용하여 선량한 풍속 기타 사회 질서를 해하거나, 타인의 권
        리를 침해하는 행위를 하여서는 아니되고, 바이러스, 애드웨어, 스파이웨어 등을
        포함하고 있는 내용 또는 제3자의 저작권 등 지적재산권을 침해하는 내용의 회원
        정보나 ‘게시물’을 게시하거나 전송 또는 배포할 수 없으며 이에 대한 모든 책임은
        '회원' 본인에게 있습니다.<br /><br />
        6. '회사'는 회원정보나 ‘게시물’이 본 조 제4항에 해당하는 경우 또는 '회사'의 규칙
        에 어긋나거나 게시판 성격에 부합하지 않는 경우, '회원'에 대한 사전 통지나 동의
        확인 절차 없이 이를 삭제하거나 등록 거부할 수 있으며, 이에 대해 '회사'는 책임을
        지지 않습니다.<br /><br />
        7. '회원'은 자신이 게시한 회원정보나 ‘게시물’을 '회사' 또는 '회사'가 허락한 제3자
        가 아래의 목적으로 사용하는 것을 허락합니다.<br /><br />
        ① '회원'이 ‘서비스’ 내에 게시하는 회원정보나 ‘게시물’은 ‘서비스’ 및 ‘회사’의 사업
        과 관련하여 제휴된 웹사이트, 매체 플랫폼 및 어플리케이션(‘제3자 매체’)에 공개
        적으로 표시되거나, 재구성되거나, 마케팅 자료로 사용되거나, 검색결과 내지 서비
        스 프로모션 등에 노출될 수 있으며, 해당 노출을 위해서 필요한 범위 내에서는 일
        부 수정, 편집되어 게시될 수 있습니다. 이 경우 '회원'은 언제든지 고객센터 또는
        ‘서비스’ 내 관리기능을 통해 해당 ‘게시물’에 대해 삭제, 비공개 등의 조치를 취할
        수 있습니다.<br /><br />
        ② '회사'는 '서비스'를 홍보하기 위한 목적으로 타 인터넷 포탈 사업자 등에게 '회
        원'의 회원정보나 ‘게시물’에 대한 검색목록 등의 정보를 제공할 수 있습니다. 단
        '회원'이 공개한 범위에 한합니다. '회원'은 언제든지 고객센터 또는 ‘서비스’ 내 관
        리기능을 통해 해당 ‘게시물’에 대해 삭제, 비공개 등의 조치를 취할 수 있습니다.<br /><br />
        8. '회사'는 '서비스'의 운영과 관련하여 ‘서비스’ 화면, 홈페이지, 전자우편, ‘서비
        스’의 전용 어플리케이션 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우
        편 등을 수신한 '회원'은 수신 거절을 '회사'에게 요청할 수 있습니다.<br /><br />
        9. '회사'는 이외의 방법으로 '회원'의 회원정보나 ‘게시물’을 이용하고자 하는 경우
        에는 전화, 팩스, 전자우편 등을 통해 사전에 '회원'의 동의를 얻어야 합니다.<br /><br />
        <h2>제 4 장 권리와 의무</h2><br />
        <h3>제 13조 (회사의 의무)</h3><br />
        1. ‘회사’는 관련 법령과 이 약관이 금지하거나 공공질서, 미풍양속에 반하는 행위
        를 하지 않으며, 이 약관이 정하는 바에 따라 지속적이고 안정적으로 ‘서비스’를 제
        공하기 위하여 최선을 다합니다.<br /><br />
        2. ‘회사’는 ‘회원’이 안전하게 ‘서비스’를 이용할 수 있도록 ‘회원’의 개인정보(신용
        정보 포함) 보호를 위한 보안 시스템을 갖추어야 하며 개인정보취급방침을 공시하
        고 준수합니다.<br /><br />
        3. ‘회사’는 전자금융거래가 안전하게 처리될 수 있도록 선량한 관리자로서의 주의
        를 다하며, 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의
        종류별로 전자적 전송이나 처리를 위한 인력, 시설, 전자적 장치 등의 정보기술 부
        분 및 전자금융업무에 관하여 금융감독위원회가 정하는 기준을 준수합니다.<br /><br />
        4. ‘회사’는 ‘서비스’ 이용과 관련하여 발생하는 ‘회원’의 불만 및 피해구제요청을 적
        절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비하며, ‘회사’는 ‘서비스’ 이
        용과 관련하여 ‘회원’으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에
        는 이를 처리하여야 합니다. ‘회원’이 제기한 의견이나 불만사항에 대해서는 전자
        우편 등을 통하여 ‘회원’에게 처리 과정 및 결과를 전달합니다.<br /><br />
        5. ‘회사’는 ‘회원’으로부터의 거래 지시가 있음에도 불구하고 천재지변, ‘회사’의 귀
        책사유가 없는 정전, 화재, 통신장애 기타의 불가항력의 사유로 지시의 처리가 불
        가능하거나 지연된 경우에는 ‘회원’에 대하여 이로 인한 책임을 지지 아니합니다.<br /><br />
        <h3>제 14조 (회원의 의무)</h3><br />
        1. ‘회원’은 ‘서비스’ 이용과 관련하여 다음 각 호의 행위를 하여서는 안 됩니다.<br /><br />
        ① 부정한 방법으로 ‘포인트’를 획득하는 행위<br /><br />
        ② ’서비스’의 정상적인 운영 및 ‘회사’의 영업을 방해하는 행위<br /><br />
        ③ 본 약관을 위반하여 ‘상점’ 및 제3자에게 손해를 끼치는 행위<br /><br />
        ④ 개인정보의 등록 또는 변경 시 허위 내용을 등록하거나 타인의 정보를 도용하는
        행위<br /><br />
        ⑤ ‘회사’가 게시한 정보를 위조 변경하는 행위<br /><br />
        ⑥ ‘회사’가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시<br /><br />
        ⑦ ‘서비스’ 및 제3자의 저작권 등 지적 재산권을 침해하는 행위<br /><br />
        ⑧ ‘서비스’ 및 제3자의 명예를 손상하거나 업무를 방해하는 행위<br /><br />
        ⑨ 사실관계를 왜곡하는 정보를 제공하는 행위<br /><br />
        ⑩ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 ‘서비
        스’에 공개 또는 게시하는 행위<br /><br />
        ⑪ ‘회사’의 동의 없이 영리를 목적으로 ‘서비스’를 사용하는 행위<br /><br />
        ⑫ ‘상점’ 정보, 사진, ‘리뷰’ 등 ‘서비스’에 게시된 ‘게시물’을 ‘회사’의 동의 없이 변
        형하거나 영리 또는 비영리 용도로 사용하는 행위<br /><br />
        ⑬ ‘서비스’를 이용할 때 아래 각 호의 행위는 하여서는 안 됩니다.<br /><br />

        (1) 이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 회원의 계정 및 비밀번
        호를 도용, 부정하게 사용하거나, 다른 사람의 명의를 사용하거나 명의자의 허락
        없이 문자메시지(SMS) 인증 등을 수행하는 행위<br /><br />
        (2) 타인의 명예를 손상시키거나 불이익을 주는 행위<br /><br />
        (3) 게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위<br /><br />
        (4) ’회사’ 또는 제3자의 저작권 등 기타 권리를 침해하는 행위<br /><br />
        (5) 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에
        게 유포하는 행위<br /><br />
        (6) ’서비스’와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴
        퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위<br /><br />
        (7) ’서비스’의 운영을 고의로 방해하거나 안정적 운영을 방해할 수 있는 정보 및 수
        신자의 명시적인 수신거부의사에 반하여 광고성 정보 또는 스팸메일(Spam Mail)
        을 전송하는 행위<br /><br />
        (8) ’회사’의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사, 수정,
        배포, 판매, 양도, 대여, 담보제공하거나 타인에게 그 이용을 허락하는 행위와 소프
        트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등 서비스를 복제, 분해 또는
        모방하거나 기타 변형하는 행위<br /><br />
        (9) 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위<br /><br />
        (10) 다른 회원의 개인정보를 수집, 저장, 공개하는 행위<br /><br />
        (11) 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로
        허위의 정보를 유통시키는 행위<br /><br />
        (12) 윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위<br /><br />
        (13) 수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는
        영상을 계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을 방해하는 행
        위<br /><br />
        (14) 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 포
        함)의 전송 또는 게시 행위<br /><br />
        (15) 회사 또는 관계사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명
        의를 도용하여 글을 게시하거나 E-mail, 메시지 등을 발송하는 행위<br /><br />
        (16) 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴
        할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램
        을 포함하고 있는 자료를 게시하거나 E-mail, 메시지 등으로 발송하는 행위<br /><br />
        (17) 기타 불법한 행위<br /><br />
        ⑭ ‘서비스’의 이용권한, 기타 이용계약상 지위를 타인에게 양도·증여, 담보로 제공
        하는 행위<br /><br />
        ⑮ 기타 본 약관 및 관련 법령을 위반하는 행위<br /><br />
        2. ‘회원’은 관계법, 본 약관의 규정, 이용안내 및 ‘서비스’와 관련하여 공지한 주의
        사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 ‘회사’의 업무에 방해되는
        행위를 하여서는 안 됩니다.<br /><br />
        3. ‘회원’ 혹은 ‘서비스’에 가입하지 않은 이용자가 본 ‘서비스’에 대한 회사의 모든
        약관과 정책, 관련 법, 규정을 준수하지 않는다면, ‘회사’는 위반행위 등을 조사할
        수 있고, ‘회원’의 계정・’서비스’ 이용을 잠시 또는 계속하여 중단하거나, 재가입에
        제한을 둘 수 있습니다.<br /><br />
        4. ‘회사’는 ‘회원’ 혹은 ‘서비스’에 가입하지 않은 이용자가 ‘서비스’와 관련된 설비
        의 오작동이나 시스템의 파괴 및 혼란을 유발하는 등 ‘서비스’ 제공에 악영향을 미
        치거나 안정적 운영을 심각하게 방해한 경우, ‘회사’는 이러한 위험 활동이 확인된
        계정들에 대하여 이용제한을 할 수 있습니다. 다만, 이용제한과 관련하여 조치 결
        과가 불만족스러울 경우, ‘회원’은 고객센터를 통해 이의를 제기할 수 있습니다.<br /><br />
        <h3>제 15 조 (개인위치정보의 이용 또는 제공)</h3><br />
        1. '회사'는 '회원'이 약관에 동의한 후 서비스 접속기기에서 '서비스'에 접속하여 '위
        치정보이용'에 동의하는 경우, 해당 '회원'의 위치정보를 이용합니다.<br /><br />
        2. 위치정보이용 동의<br /><br />
        ① 위치측정이 가능한 기능이 탑재된 접속기기로 '서비스'에 접속하여 '위치정보이
        용'에 동의한 경우에만 위치정보를 이용하고, 동의하지 않은 이용자의 경우 '서비
        스' 접속 시 마다 '위치정보이용' 동의 요청을 할 수 있습니다. 동의하지 않은 이용
        자의 경우 서비스 접속기기에서 정상적인 ‘서비스’ 이용이 어려울 수 있습니다.<br /><br />
        ② '회원'이 서비스 접속기기에서 자동 로그인 기능을 선택하지 않는 경우, '회원'은
        서비스 접속기기에서 '서비스'에 로그인할 때마다 '위치정보이용' 동의 여부를 선택
        할 수 있습니다.<br /><br />
        ③ '위치정보이용'에 동의를 한 '회원'은 서비스 이용 시 본인의 위치를 자의적으로
        노출하였다고 간주하며, '회사'는 이용자의 위치정보를 바탕으로 컨텐츠를 제공합
        니다.<br /><br />
        ④ 장소 정보 및 컨텐츠 입력 등 ‘서비스’ 이용 시 회사는 '회원'이 생성한 컨텐츠에
        대해서만 저장하며 '회원'의 위치에 대한 정보를 저장, 보존하지 않습니다.<br /><br />
        ⑤ '회사'는 위치정보활용을 동의한 후 ‘서비스’에 가입 또는 로그인하고 어플리케
        이션을 구동하여 위치를 수신 받은 ‘회원’의 위치를 기반으로 컨텐츠를 추천하기
        위하여 '위치정보'를 이용합니다.<br /><br />
        ⑥ '회원'은 원하는 경우 언제든지 ‘서비스’ 탈퇴를 할 수 있으며, ‘서비스’ 탈퇴의 경
        우 위치정보 이용에 대한 ‘회원’의 동의를 철회하는 것으로 간주합니다.<br /><br />
        3. ‘회원’ 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용자의 주소에 의
        하며, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다
        만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국에 거주하는 경우
        에는 민사소송법상의 관할법원에 제기합니다.<br /><br />
        4. ‘회사’는 타사업자 또는 ‘회원‘과의 요금정산 및 민원처리를 위해 위치정보 이용•
        제공•사실 확인자료를 자동 기록•보존하며, 해당 자료는 1년간 보관합니다.<br /><br />
        <h3>제 16 조 (개인위치정보주체의 권리)</h3><br />
        1. ‘회원’은 ‘회사’에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공
        및 개인위치정보의 제3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니
        다. 이 경우 ‘회사’는 수집한 개인위치정보 및 위치정보 이용, 제공사실 확인자료를
        파기합니다.<br /><br />
        2. ‘회원’은 ‘회사’에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시
        적인 중지를 요구할 수 있으며, ‘회사’는 정당한 사유 없이 이를 거절할 수 없고 이
        를 위한 기술적 수단을 갖추고 있습니다.<br /><br />
        3. ‘회원’은 ‘회사’에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수
        있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우
        ‘회사’는 정당한 사유 없이 ‘회원’의 요구를 거절할 수 없습니다.<br /><br />
        ① 본인에 대한 위치정보 이용, 제공사실 확인자료<br /><br />
        ② 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법
        률 규정에 의하여 제3자에게 제공된 이유 및 내용<br /><br />
        4. ‘회원’은 본 조 제1항부터 제3항의 권리행사를 위해 ‘회사’가 정하는 소정의 절차
        를 거쳐 ‘회사’에게 ‘회원’의 권리행사 수반 절차의 이행을 요구할 수 있습니다.<br /><br />
        <h3>제 17 조(법정대리인의 권리)</h3><br />
        1. ‘회사’는 14세 미만의 ‘회원’에 대해서는 개인위치정보를 이용한 위치기반서비
        스 제공 및 개인위치정보의 제3자 제공에 대한 동의를 당해 ‘회원’과 당해 ‘회원’의
        법정대리인으로부터 받아야 합니다. 이 경우 법정대리인은 제16조에 의한 ‘회원'의
        권리를 모두 가집니다.<br /><br />
        2. ‘회사‘는 14세 미만의 아동의 개인위치정보 또는 위치정보 이용•제공사실 확인
        자료를 이용약관에 명시 또는 고지한 범위를 넘어 이용하거나 제3자에게 제공하고
        자 하는 경우에는 14세 미만의 아동과 그 법정대리인의 동의를 받아야 합니다.<br /><br />
        <h3>제 18 조 (위치정보관리책임자의 지정)</h3><br />
        1. ‘회사’는 위치정보를 적절히 관리•보호하고 개인위치정보주체의 불만을 원활히
        처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임
        자로 지정해 운영합니다.<br /><br />
        2. 위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서, 구체
        적인 사항은 본 약관의 부칙에 따릅니다.<br /><br />
        <h3>제 19 조 (‘회원’의 ‘아이디’ 및 ‘비밀번호’의 관리에 대한 의무)</h3><br />
        1. ‘회원’의 ‘아이디’와 ‘비밀번호’에 관한 관리책임은 ‘회원’에게 있으며, 이를 제3자
        가 이용하도록 하여서는 안 됩니다.<br /><br />
        2. ‘회사’는 ‘회원’의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양
        속에 어긋나거나 ‘회사’ 및 ‘회사’의 운영자로 오인할 우려가 있는 경우, 해당 아이
        디의 이용을 제한할 수 있습니다.<br /><br />
        3. ‘회원’은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경
        우에는 이를 즉시 ‘회사’에 통지하고 ‘회사’의 안내에 따라야 합니다.<br /><br />
        4. 본 조 제3항의 경우에 해당 ‘회원’이 ‘회사’에 그 사실을 통지하지 않거나, 통지한
        경우에도 ‘회사’의 안내에 따르지 않아 발생한 불이익에 대하여 ‘회사’는 책임지지
        않습니다.<br /><br />
        <h3>제 20 조 (포인트) 포인트 제도는 대구 상인연합회 및 대구 지자체와 협의하여 작성</h3><br />
        1. ’서비스’를 이용하면서 ‘회사’가 제시한 조건을 충족하는 경우, ‘회사’는 정해진
        정책에 따라 ‘회원’의 계정에 ‘포인트’를 부여할 수 있습니다.<br /><br />
        2. 본 조에서 정하고 있는 내용 외에 ‘포인트’ 부여 및 이용에 관한 상세한 사항은
        ‘회사’가 별도로 정한 운영정책을 따르며, ‘회사’는 고객센터 도움말 페이지 등을 통
        하여 ‘회원’에게 안내합니다.<br /><br />
        <h3>제 21 조 (큐브)</h3><br />
        1. ’서비스’를 이용하면서 ‘회사’가 제시한 조건을 충족하는 경우 ‘회사’의 정해진 정
        책에 따라 ‘회원’은 ‘큐브’의 ‘사용권’을 구매 또는 획득할 수 있습니다.<br /><br />
        2. ‘회원’은 ’회사’가 정하는 절차와 조건에 따라 ‘큐브’의 ‘사용권’에 부여되는 서비
        스 등을 이용할 수 있습니다. 또한 ‘큐브’의 ‘사용권’에 부여되는 ‘서비스’는 ‘회사’가
        제공하는 것에 한합니다.<br /><br />
        3. ‘회사’ 이외의 제휴사 기타 제3자가 제공하는 서비스의 경우, 그에 대한 안전성
        및 적법성 등에 대해 보증하거나 책임지지 않으며, 관련된 일체의 위험의 부담에
        대해 ‘회원’이 해당 서비스를 사용하기 전에 동의함을 원칙으로 합니다.<br /><br />
        4. ’큐브’의 ‘사용권’으로 이용할 수 있는 ‘회사’의 ‘서비스’ 등은 ‘회사’의 정책과 제3
        자와의 제휴 관계에 따라 수시로 변경될 수 있습니다.<br /><br />
        5. 회사는 ‘큐브’의 ‘사용권’을 획득함에 따른 현금으로의 환전 및 환불 등을 제공하지 않습니다.<br /><br />
        6. ‘큐브’의 ‘사용권’은 재산적 가치가 없으며, 회사는 ‘큐브’의 ‘사용권’을 획득한 ‘회
        원’이 얻을 수 있는 수익 및 보상을 보장하지 않습니다.<br /><br />
        7. ’회사’는 개별 서비스 운영상의 목적과 ‘큐브’ ‘사용권’ 보유자의 효율적 이용을
        지원하기 위해 필요한 경우, 부득이하게 정책에 정해진 바에 따라 ‘큐브’의 일부 또
        는 전부를 조정하거나 이용 제한 또는 소멸 등의 조치를 취할 수 있습니다.<br /><br />
        8. 본 조에서 정하고 있는 내용 외에 ‘큐브’에 대한 ‘사용권’의 구매, 획득 및 이용에
        관한 상세한 사항은 ‘회사’가 별도로 정한 운영정책을 따르며, ‘회사’는 고객센터 도
        움말 페이지 등을 통하여 ‘회원’에게 안내합니다.<br /><br />
        <h3>제 22 조 (손해배상)</h3><br />
        1. ’회사’는 법령상 허용되는 한도 내에서 ‘서비스’와 관련하여 본 약관에 명시되지
        않은 어떤 구체적인 사항에 대한 약정이나 보증을 하지 않습니다. 또한, ‘회사’는
        ‘회사’, ‘상점’, ‘가맹점’, 기타 제휴사 및 제3자가 제공하거나 ‘회원’이 작성하는 등의
        방법으로 ‘서비스’에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증
        을 하지 않으며, ‘회사’의 과실 없이 발생된 ‘회원’의 손해에 대하여는 책임을 부담
        하지 아니합니다.<br /><br />
        2. ’회사’는 아래와 같이 발생된 손해에 대해서는 책임을 부담하지 않습니다. 또한
        ‘회사’는 법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적
        손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다.<br /><br />
        ① 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해<br /><br />
        ② ’회원’의 귀책사유로 ‘서비스’ 이용에 장애가 발생한 경우<br /><br />
        ③ ‘서비스’에 접속 또는 이용과정에서 발생하는 개인적인 손해<br /><br />
        ④ 제3자가 불법적으로 ‘회사’의 서버에 접속하거나 서버를 이용함으로써 발생하
        는 손해<br /><br />
        ⑤ 제3자가 ‘회사’ 서버에 대한 전송 또는 ‘회사’ 서버로부터의 전송을 방해함으로
        써 발생하는 손해<br /><br />
        ⑥ 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해<br /><br />
        ⑦ 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제3자가 ‘서
        비스’를 이용하는 과정에서 발생된 손해<br /><br />
        ⑧ 기타 ‘회사’의 고의 또는 과실이 없는 사유로 인해 발생한 손해<br /><br />
        <h3>제 23 조 (회사의 면책)</h3><br />
        1. ‘회사’는 전시, 사변, 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 기
        술적 결함 기타 불가항력적 사유로 ‘서비스’를 제공할 수 없는 경우에는 책임이 면제됩니다.<br /><br />
        2. ‘회사’는 ‘회원’의 귀책사유로 인한 ‘서비스’의 중지, 이용장애 및 계약해지에 대
        하여 책임이 면제됩니다.<br /><br />
        3. ‘회사’는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하
        지 아니하여 ‘회원’에게 손해가 발생한 경우에 대해서 회사의 고의 또는 중대한 과
        실이 없는 한 책임이 면제됩니다.<br /><br />
        4. ‘회사’는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이
        한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대해서 ‘회사’의 고의 또는
        중대한 과실이 없는 한 책임이 면제됩니다.<br /><br />
        5. ‘회사’는 ‘회원’의 컴퓨터 환경으로 인하여 발생하는 제반 문제 또는 ‘회사’의 고
        의 또는 중대한 과실이 없는 네트워크 환경으로 인하여 발생하는 문제에 대해서 책
        임이 면제됩니다.<br /><br />
        6. ‘회사’는 ‘회원’ 또는 제3자가 서비스 내 또는 웹사이트 상에 게시 또는 전송한 정
        보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해서는 ‘회사’의 고의 또는 중대한
        과실이 없는 한 책임이 면제됩니다.<br /><br />
        7. ‘회사’는 ‘회원’ 상호간 또는 ‘회원’과 제3자간에 서비스를 매개로 발생한 분쟁에
        대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.<br /><br />
        8. ‘회사’가 제공하는 ‘서비스’ 중 무료 서비스의 경우에는 ‘회사’의 고의 또는 중대
        한 과실이 없는 한 ‘회사’는 손해배상을 하지 않습니다.<br /><br />
        9. 본 ‘서비스’ 중 일부의 ‘서비스’는 다른 사업자가 제공하는 서비스를 통하여 제공
        될 수 있으며, ’회사’는 다른 사업자가 제공하는 서비스로 인하여 발생한 손해 등에
        대해서는 ‘회사’의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.<br /><br />
        10. ‘회사’는 ‘회원’이 ‘서비스’를 이용하며 기대하는 결과를 얻지 못하거나 상실한
        것에 대하여 책임을 지지 않으며, 서비스에 대한 취사선택 또는 이용으로 발생하는
        손해 등에 대해서 ‘회사’의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.<br /><br />
        11. ‘회사’는 ‘회원’의 ‘서비스’ 내의 재화, 유료 컨텐츠, 포인트 등의 손실에 대하여
        ‘회사’의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.<br /><br />
        12. ‘회사’는 ‘회원’의 컴퓨터 오류에 의한 손해가 발생한 경우 또는 신상정보 및 전
        자우편주소를 부정확하게 기재하거나 미기재하여 손해가 발생한 경우에 대하여
        ‘회사’의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.<br /><br />
        13. ‘회사’는 관련 법령, 정부 정책 등에 따라 ‘서비스’ 또는 ‘회원’에 따라 ‘서비스’
        이용시간 등을 제한할 수 있으며, 이러한 제한사항 및 제한에 따라 발생하는 ‘서비
        스’ 이용 관련 제반 사항에 대해서는 책임이 면제됩니다.<br /><br />
        14. ‘회사’는 ‘회원’이 비밀번호 또는 인증수단을 소홀히 관리하거나 도용, 위조나
        변조를 방지하기 위하여 충분한 주의를 기울이지 않아 발생하는 서비스 이용상의
        손해 또는 제3자 결제에 대해 책임을 지지 않습니다. 다만, ’회사’의 고의 또는 중과
        실에 의한 경우에는 그러하지 아니합니다.<br /><br />
        15. ‘회원’이 모바일 기기의 변경, 모바일 기기의 번호 변경, 운영체제(OS) 버전의
        변경, 해외 로밍, 통신사 변경 등으로 인해 콘텐츠 전부나 일부의 기능을 이용할 수
        없는 경우 ‘회사’는 이에 대해 책임을 지지 않습니다. 다만, ’회사’의 고의 또는 중과
        실에 의한 경우에는 그러하지 아니합니다.<br /><br />
        16. ‘회원’이 ‘회사’가 제공하는 콘텐츠나 계정정보를 삭제한 경우 ‘회사’는 이에 대
        해 책임을 지지 않습니다. 다만, ’회사’의 고의 또는 중과실에 의한 경우에는 그러하지 
        아니합니다.<br /><br />
        17. ‘회사’는 ‘비회원’의 ‘서비스’ 이용으로 발생한 손해에 대해서는 책임을 지지 않
        습니다. 다만, ’회사’의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.<br /><br />
        <h3>제 24 조 (회원에 대한 통지)</h3><br />
        1. ‘회사’가 ‘회원’에게 통지를 하는 경우 ‘회원’의 전자우편주소, 전자메모, 메신저
        서비스, ‘서비스’ 내 쪽지, 문자 메시지 등으로 할 수 있습니다.<br /><br />
        2. ‘회사’는 ‘회원’ 전체에게 통지를 하는 경우 7일 이상 ‘서비스’ 내에 게시하거나
        팝업 화면 등을 제시함으로써 본 조 제1항의 통지에 갈음할 수 있습니다.<br /><br />
        <h3>제 25 조 (분쟁의 해결)</h3><br />
        1. 본 약관에 관하여 분쟁이 발생할 경우 대한민국의 법률에 따릅니다.<br /><br />
        2. ‘서비스’의 이용과 관련하여 분쟁이 발생할 경우 관할법원은 민사소송법상 관할
        법원으로 합니다.<br /><br />
        3. ‘회사’는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거
        나 협의를 할 수 없는 경우에는 위치정보의 보호 및 이용 등에 관한 법률 제28조의
        규정에 의한 방송통신위원회에 재정을 신청할 수 있습니다.<br /><br />
        4. ‘회사’ 또는 ‘회원’은 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지
        지 아니하거나 협의를 할 수 없는 경우에는 개인정보보호법 제40조의 규정에 의한
        개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.<br /><br />
        [부칙]
        1. (시행일) 이 약관은 2021 년 09월 21일부터 시행됩니다.<br /><br />
        2. (시행일) 이 약관은 2021년 09월 21일부터 시행됩니다. (개정)<br /><br />
        3. 위치정보관리책임자는 2021년 09월 21일을 기준으로 다음과 같이 지정합니다.<br /><br />
        ① 이름 : 이선진<br /><br />
        ② 소속 : 주식회사 루몽스튜디오<br /><br />
        ③ 연락처 : 1234-4567<br /><br />
      </div>
    </Wrapper>
  );
};

export default Service;

const Wrapper = styled.div`
margin-left: 25%;
max-width: 50%;
min-height: 80vh;
.text{
  text-align:left;
  font-size: 20px;
  padding-bottom: 30px;
}
h1{
  font-size: 35px;
  padding-top: 30px;
  padding-bottom: 30px;
}
span{
  font-size: 22px;
}
`;

