import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import apiCode from '../lib/api/apiCode';
import { apiCheckBuyCube, apiBuyCube } from '../lib/api/apiCube';
import tilebelt from '@mapbox/tilebelt';

const Buy_Cube = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [address, setAddress] = useState('');
  const [cubeNum, setCubeNum] = useState(0);
  const [oneCubePrice, setOneCubePrice] = useState(0);
  const [totalCubePrice, setTotalCubePrice] = useState(0);
  const [myPoint, setMyPoint] = useState(0);
  const [cubeInfoList, setCubeInfoList] = useState([]);

  const cubeNum2 = cubeNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const oneCubePrice2 = oneCubePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const myPoint2 = myPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const totalCubePrice2 = totalCubePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  


  // 구매할 큐브 확인 api 호출
  const apiCallCheckBuyCube = async cubeList => {
    const data = await apiCheckBuyCube(cubeList);
    // 세션이 끊어진 상태
    if (data.resultCode === apiCode.error.needLogin) {
      navigate('/login');
      return;
    }

    if (data.resultCode === apiCode.error.success) {
      setAddress(data.address);
      setCubeNum(data.cubeNum);
      setOneCubePrice(data.cubePrice);
      setTotalCubePrice(data.totalPrice);
      setMyPoint(data.myPoint);
      addCubeInfo(cubeList);
    }
  };

  // 큐브 정보 추가
  const addCubeInfo = cubeList => {
    const list = [];
    for (let cube of cubeList) {
      const tile = tilebelt.quadkeyToTile(cube);
      const geoJson = tilebelt.tileToGeoJSON(tile);

      const centerLongitude =
        geoJson.coordinates[0][0][0] +
        (geoJson.coordinates[0][3][0] - geoJson.coordinates[0][0][0]) * 0.5;
      const centerLatitude =
        geoJson.coordinates[0][0][1] +
        (geoJson.coordinates[0][1][1] - geoJson.coordinates[0][0][1]) * 0.5;

      const jsonData = {
        cubeNo: cube,
        longitude: centerLongitude.toFixed(6),
        latitude: centerLatitude.toFixed(6),
      };
      list.push(jsonData);
    }
    setCubeInfoList(list);
  };
console.log("구매직전..!")
  // 구매하기 버튼 클릭
  const handleBuyCube = async () => {
    if (!state.cubeList) return;

    if (myPoint < totalCubePrice) {
      // 포인트 부족
      return;
    }

    const data = await apiBuyCube('', state.cubeList);
    // 세션이 끊어진 상태
    if (data.resultCode === apiCode.error.needLogin) {
      navigate('/login');
      return;
    }

    if (data.resultCode === apiCode.error.success) {
      navigate('/Cube_success', {
        state: {
          address: address,
          cubeNum: cubeNum,
          totalCubePrice: totalCubePrice,
          cubeInfoList: cubeInfoList,
          myPoint: myPoint,
        },
      });
    }
  };
  console.log("구매 버튼 클릭 전..!!")

  const handleToggleOpen = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  useEffect(() => {
    if (state.cubeList) {
      apiCallCheckBuyCube(state.cubeList);
    }
  }, []);



  return (
    <>
    <Wrapper>
      <ul className="title">
        <li>
          <h1>구매하기</h1>
        </li>
        <li></li>
      </ul>
      <ul className="cube_info">
        <li>
          <text>{address}</text>
        </li>
        <li>
          <button onClick={handleToggleOpen}>
            더보기
          </button>
        </li>
      </ul>
    </Wrapper>
    <NavMenu isToggleOpen={isToggleOpen}>
      <ul>
            <li>
              <text className="list_title">Block ID</text>
              {cubeInfoList.map(item => (
                <text className="cube_list" key={item.cubeNo}>
                  {item.cubeNo}
                </text>
              ))}
            </li>
            <li>
              <text className="list_title2">경도/위도</text>
              {cubeInfoList.map(item => (
                <text className="cube_list" key={item.cubeNo}>
                  {item.longitude}, {item.latitude}
                </text>
              ))}
            </li>
            </ul>
          </NavMenu>
      <Wrap>
        <ul className="info">
          <li>
            <text>선택된 큐브</text>
          </li>
          <li>
            <text>{cubeNum2}/800</text>
          </li>
        </ul>
        <ul className="info">
          <li>
            <text>큐브 가격(1개)</text>
          </li>
          <li>
            <text>{oneCubePrice2}P</text>
          </li>
        </ul>
        <ul className="info">
          <li>
            <text>총 가격</text>
          </li>
          <li>
            <text>{totalCubePrice2}P</text>
          </li>
        </ul>
        <ul className="info2">
          <li>
            <h3>보유 포인트</h3>
          </li>
          <li>
          </li>
        </ul>
        <ul className="info2">
          <li>
            <text>포인트</text>
          </li>
          <li>
          <Link to={'/point'}>
              <button>충전하기</button>
          </Link>
          </li>
        </ul>
        <ul className="info3">
          <li>
          </li>
          <li>
          <text>{myPoint2}P</text>
          </li>
        </ul>
        <ul className="info4">
          <li>
            <text>총 결제 금액</text>
          </li>
          <li>
            <text>{totalCubePrice2}P</text>
          </li>
        </ul>
        <ul>
          <li>
          <Link to={'/cube'}>
              <button className="back_btn2"> 돌아가기</button>
            </Link>
          </li>
          <li>
              <button className="back_btn" onClick={handleBuyCube}>구매하기</button>
          </li>
        </ul>
      </Wrap>
      </>
  );
};

export default Buy_Cube;
const Wrapper = styled.div`
display: flex;
width: 100%;
font-family: 'NanumSquareBold';
flex-direction: column;
ul{
  width: 52.5%;
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin-left: 25%;
  margin-top: 20px;

}
li+li{
}
.title{
  margin-top: 100px;
  border-bottom: 1px solid lightgray;
  padding-bottom: -500px;
}

.cube_list {
  text-decoration: none;
  display: block;
}


button {
  padding: 5px 20px 5px 20px;
  background: none;
  border-radius: 5px;
  margin-top: 55px;
  margin-bottom: 10px;
  font-family: 'NanumSquareBold';
}
@media only screen and (min-width: 769px) and (max-width:1199px){
  ul{
    width: 52.5%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 25%;
    margin-top: 20px;

  }
  li+li{
  }
  .title{
    margin-top: 100px;
    border-bottom: 1px solid lightgray;
    font-size: 15px;
  }

  .cube_list {
    text-decoration: none;
    display: block;
  }

  button {
    padding: 5px 20px 5px 20px;
    background: none;
    border-radius: 5px;
    margin-top: 55px;
    margin-bottom: 10px;
    font-family: 'NanumSquareBold';
  }
}
@media only screen and (min-width: 601px) and (max-width:768px){
  ul{
    width: 52.5%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 25%;
    margin-top: 20px;

  }
  li+li{
  }
  .title{
    margin-top: 100px;
    border-bottom: 1px solid lightgray;
    font-size: 13px;
  }

  .cube_list {
    text-decoration: none;
    display: block;
  }

  button {
    padding: 5px 20px 5px 20px;
    font-size: 12px;
    background: none;
    border-radius: 5px;
    margin-top: 55px;
    margin-bottom: 10px;
    font-family: 'NanumSquareBold';
  }
}
@media only screen and (max-width:600px){
  ul{
    width: 52.5%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 25%;
    margin-top: 15px;

  }
  li+li{
  }
  .title{
    margin-top: 90px;
    border-bottom: 1px solid lightgray;
    font-size: 11px;
  }

  .cube_list {
    text-decoration: none;
    display: block;
  }

  button {
    padding: 5px 20px 5px 20px;
    font-size: 12px;
    background: none;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'NanumSquareBold';
  }
}
`;

const Wrap = styled.div`
display: flex;
width: 100%;
height: auto;
min-height: 100%;
padding-bottom: 230px;
font-family: 'NanumSquareBold';
flex-direction: column;
ul{
  width: 52.5%;
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin-left: 25%;
  margin-top: 20px;

}
li+li{
}
.title{
  margin-top: 100px;
  border-bottom: 1px solid lightgray;
  padding-bottom: -500px;
}

.info {
  padding-top: 40px;
  color: black;
  font-size: 22px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgray;
}  
.info2 {
  padding-top: 20px;
  color: black;
  font-size: 22px;
}
.info3 {
  padding-top: 20px;
  padding-bottom: 20px;
  color: black;
  font-size: 22px;
  border-bottom: 1px solid lightgray;
}
.info4 {
  padding: 10px 10px 10px 10px;
  color: black;
  font-size: 22px;
  border: 1px solid none;
  border-radius: 8px;
  background:#F5F5F5;
} 
.back_btn2{
  padding: 10px 5px; 10px; 5px;
  margin-top: 80px;
  margin-bottom: 50px;
  width: 120px;
  color: #FF5100;
  background: ;
  text-align: center;
  border: 2px solid #FF5100;
  border-radius: 5px;
}

.back_btn{
  padding: 10px 5px; 10px; 5px;
  margin-top: 80px;
  margin-bottom: 50px;
  width: 120px;
  color: white;
  background: #FF5100;
  text-align: center;
  border:none;
  border-radius: 5px;
}
.back_btn2{
  padding: 10px 5px; 10px; 5px;
  margin-top: 80px;
  margin-bottom: 50px;
  width: 120px;
  color: #FF5100;
  background: ;
  text-align: center;
  border: 2px solid #FF5100;
  border-radius: 5px;
}
button {
  padding: 5px 20px 5px 20px;
  background: none;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: 'NanumSquareBold';
}
@media only screen and (min-width: 769px) and (max-width:1199px){
  ul{
    width: 52.5%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 25%;
    margin-top: 20px;

  }
  li+li{
  }
  .title{
    margin-top: 100px;
    border-bottom: 1px solid lightgray;
    font-size: 15px;
  }

  .info {
    padding-top: 40px;
    color: black;
    font-size: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
  }
  .info2 {
    padding-top: 20px;
    color: black;
    font-size: 20px;
  }
  .info3 {
    padding-top: 20px;
    color: black;
    font-size: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
  }
  .info4 {
    padding: 10px 10px 10px 10px;
    color: black;
    font-size: 20px;
    border: 1px solid none;
    border-radius: 8px;
    background:#F5F5F5;
  }

  .back_btn2{
    padding: 10px 5px; 10px; 5px;
    margin-top: 80px;
    margin-bottom: 50px;
    width: 120px;
    color: #FF5100;
    background: ;
    text-align: center;
    border: 2px solid #FF5100;
    border-radius: 5px;
  }
  
.back_btn{
    padding: 10px 5px; 10px; 5px;
    margin-top: 80px;
    margin-bottom: 50px;
    width: 120px;
    color: white;
    background: #FF5100;
    text-align: center;
    border:none;
    border-radius: 5px;
  }

  button {
    padding: 5px 20px 5px 20px;
    background: none;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: 'NanumSquareBold';
  }
}
@media only screen and (min-width: 601px) and (max-width:768px){
  ul{
    width: 52.5%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 25%;
    margin-top: 20px;

  }
  li+li{
  }
  .title{
    margin-top: 100px;
    border-bottom: 1px solid lightgray;
    font-size: 13px;
  }
  .info {
    padding-top: 40px;
    color: black;
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
  }
  .info2 {
    padding-top: 10px;
    color: black;
    font-size: 18px;
  }
  .info3 {
    padding-top: 10px;
    color: black;
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
  }
  .info4 {
    padding: 10px 10px 10px 10px;
    color: black;
    font-size: 18px;
    border: 1px solid none;
    border-radius: 8px;
    background:#F5F5F5;
  } 
  .back_btn2{
    padding: 10px 5px; 10px; 5px;
    margin-top: 80px;
    margin-bottom: 50px;
    width: 100px;
    color: #FF5100;
    text-align: center;
    border: 2px solid #FF5100;
    border-radius: 5px;
  }

.back_btn{
    padding: 10px 5px; 10px; 5px;
    margin-top: 80px;
    width: 100px;
    color: white;
    background: #FF5100;
    text-align: center;
    border:none;
    border-radius: 5px;
  }

  button {
    padding: 5px 20px 5px 20px;
    font-size: 12px;
    background: none;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: 'NanumSquareBold';
  }
}
@media only screen and (max-width:600px){
  ul{
    width: 52.5%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 25%;
    margin-top: 15px;

  }
  li+li{
  }
  .title{
    margin-top: 90px;
    border-bottom: 1px solid lightgray;
    font-size: 11px;
  }

  .cube_list {
    text-decoration: none;
    display: block;
  }

  .info {
    padding-top: 40px;
    color: black;
    font-size: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
  }
  .info2 {
    padding-top: 5px;
    color: black;
    font-size: 15px;
  }
  .info3 {
    padding-top: 5px;
    color: black;
    font-size: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
  }
  .info4 {
    padding: 10px 10px 10px 10px;
    color: black;
    font-size: 15px;
    border: 1px solid none;
    border-radius: 8px;
    background:#F5F5F5;
  } 
  .back_btn2{
    padding: 10px 5px; 10px; 5px;
    margin-top: 60px;
    margin-bottom: 50px;
    width: 100px;
    color: #FF5100;
    text-align: center;
    border: 2px solid #FF5100;
    border-radius: 5px;
  }

.back_btn{
    padding: 10px 5px; 10px; 5px;
    margin-top: 60px;
    margin-bottom:100px;
    width: 100px;
    color: white;
    background: #FF5100;
    text-align: center;
    border:none;
    border-radius: 5px;
  }

  button {
    padding: 5px 20px 5px 20px;
    font-size: 12px;
    background: none;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: 'NanumSquareBold';
  }
}
`;

const NavMenu = styled.ul`
  display: ${props => (props.isToggleOpen ? 'block' : 'none')};
  width: 100%;
  height: auto;
  min-height: 100%;
  font-family: 'NanumSquareBold';
  flex-direction: column;
  ul{
    width: 52.5%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 25%;
    margin-top: 20px;

  
  }
  li+li{
  }
  .cube_list {
    text-decoration: none;
    color: black;
    display: block;
    font-size: 16px;
    text-align: right;
    padding: 5px 5px;
  }
  .list_title {
    text-decoration: none;
    color: black;
    display: block;
    text-align: left;
    padding: 5px 5px;
    font-size: 22px;
  }
  .list_title2 {
    text-decoration: none;
    color: black;
    display: block;
    text-align: right;
    padding: 5px 5px;
    font-size: 22px;
  }
  @media only screen and (min-width: 601px) and (max-width:768px){
    ul{
      width: 52.5%;
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-left: 25%;
      margin-top: 10px;
    }
    li+li{
    }
    .cube_list {
      text-decoration: none;
      color: black;
      display: block;
      font-size: 12px;
      text-align: right;
      padding: 5px 5px;
    }
    .list_title {
      text-decoration: none;
      color: black;
      display: block;
      text-align: left;
      padding: 5px 5px;
      font-size: 16px;
    }
    .list_title2 {
      text-decoration: none;
      color: black;
      display: block;
      text-align: right;
      padding: 5px 5px;
      font-size: 16px;
    }
  }
  @media only screen and (max-width:600px){
    ul{
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-top: 10px;
    }
    li+li{
    }
    .cube_list {
      text-decoration: none;
      color: black;
      display: block;
      font-size: 10px;
      text-align: right;
      padding: 5px 5px;
    }
    .list_title {
      text-decoration: none;
      color: black;
      display: block;
      text-align: left;
      padding: 5px 5px;
      font-size: 16px;
    }
    .list_title2 {
      text-decoration: none;
      color: black;
      display: block;
      text-align: right;
      padding: 5px 5px;
      font-size: 16px;
    }
  }
`;

