/**
 * cube 관련 api 모듈 입니다.
 */

import api from './api.js';

/**
 * 구매한 큐브 번들 리스트 조회 api 입니다.
 *
 * @param {number} page 요청 페이지 번호
 */
export const apiBundleList = async page => {
  const bodyData = {
    page,
  };
  const requestData = {
    data: bodyData,
  };

  const { data } = await api.post(
    '/cube/bundlelist',
    JSON.stringify(requestData)
  );

  return data.data;
};

/**
 * 지도 정보 확인 api 입니다.
 *
 * @param {array} cubeList 화면에 표시된 큐브 리스트
 */
export const apiMapInfo = async cubeList => {
  const bodyData = {
    cubeList,
  };
  const requestData = {
    data: bodyData,
  };

  const { data } = await api.post('/cube/mapinfo', JSON.stringify(requestData));

  return data.data;
};

/**
 * 선택한 하나의 큐브 정보 확인 api 입니다.
 *
 * @param {string} 정보 요청할 큐브 번호
 */
export const apiCubeInfo = async cubeNo => {
  const bodyData = {
    cubeNo,
  };
  const requestData = {
    data: bodyData,
  };

  const { data } = await api.post(
    '/cube/cubeinfo',
    JSON.stringify(requestData)
  );

  return data.data;
};

/**
 * 큐브 구매 전 정보 확인 api 입니다.
 *
 * @param {array} cubeList 구매할 큐브 리스트
 */
export const apiCheckBuyCube = async cubeList => {
  const bodyData = {
    cubeList,
  };
  const requestData = {
    data: bodyData,
  };

  const { data } = await api.post(
    '/cube/checkbuycube',
    JSON.stringify(requestData)
  );

  return data.data;
};

/**
 * 큐브 구매 api 입니다.
 *
 * @param {string} referralCode 추천인 코드
 * @param {array} cubeList 구매할 큐브 리스트
 */
export const apiBuyCube = async (referralCode, cubeList) => {
  const bodyData = {
    referralCode,
    cubeList,
  };
  const requestData = {
    data: bodyData,
  };

  const { data } = await api.post('/cube/buycube', JSON.stringify(requestData));

  return data.data;
};
