import styled from "styled-components";


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  h1 {
    color: #fab005;
  }
`;

const CS_Center = () => {
  return (
    <Wrapper>
      <h1>고객센터입니다</h1>
    </Wrapper>
  );
};

export default CS_Center;