import styled from 'styled-components';
import { Link } from 'react-router-dom';
import React from 'react';
import bg1 from '../img/bg1.png';
import bg2_3 from '../img/bg2_3.png';
import bg4 from '../img/bg4.png';
import bg4_1 from '../img/bg4_1.png';
import bg4_2 from '../img/bg4_2.png';
import bg4_3 from '../img/bg4_3.png';
import bg5 from '../img/bg5.png';
import bg5_1 from '../img/bg5_1.png';
import bg5_2 from '../img/bg5_2.png';
import bg5_3 from '../img/bg5_3.png';
import HomeVideo from '../video/homevideo.mp4';
import { opacityVariants } from '../hooks/animation';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const useObserver = () => {
  const animation = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    } else {
      animation.start('hidden');
    }
  }, [animation, inView]);

  return { ref, animation };
};
const Main = () => {
  const { ref, animation } = useObserver();
  return (
    <Wrapper>
      <div>
        <div>
          <video
            muted
            autoPlay
            loop
            src={HomeVideo}
            width="100%"
            height="100%"
            alt="HomeVideo"
          />
        </div>
        <motion.div
          ref={ref}
          initial="hidden"
          animate={animation}
          variants={opacityVariants}
        >
          <div className="pic">
            <div className="bg1">
              {/*eslint-disable-next-line*/}
              <img src={bg1} />
              <div className="bg1-text">
                No.1 대구 공간
                <br />
                메타버스 플랫폼
                <br />
                <br />
                <br />또 다른 대구,
                <br />
                <span>OTTERLAND</span>
              </div>
            </div>
            <div className="bg2_3">
              <img src={bg2_3} />
            </div>
            <div className="bg4">
              <img src={bg4} alt="" />
              <div className="bg4_text">
                Cube를 구매하여
                <br />
                오너가 되어보세요!
                <img className="bg4_1" src={bg4_1} alt="" />
                <img className="bg4_2" src={bg4_2} alt="" />
                <img className="bg4_3" src={bg4_3} alt="" />
              </div>
              <Link to={'/cube'}>
                <text className="bg4_btn">구매하기</text>
              </Link>
            </div>
            <div className="bg5">
              <img src={bg5} alt="" />
              <div className="bg5_text">
                {/*eslint-disable-next-line*/}
                다양한 액티비티에 참여하여
                <br />
                보상을 받아보세요!
                <br />
                <img className="bg5_1" src={bg5_1} alt="" />
                <img className="bg5_2" src={bg5_2} alt="" />
                <img className="bg5_3" src={bg5_3} alt="" />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </Wrapper>
  );
};
export default Main;

const Wrapper = styled.div`
  overflow: scroll;
  display: flex;
  background: white;
  min-height: 80vh;
  padding: 0px;
  margin: 0px;
  img {
    display: block;
    background: white;
    width: 100vw;
    height: 100%;
  }
  span {
    color: #ff5100;
    font-family: 'Aldrich', sans-serif;
  }
  span1 {
    color: #ff5100;
    font-family: 'Aldrich', sans-serif;
  }
  video {
    display: inline-block;
    vertical-align: bottom;
  }
  .bg1 {
    position: relative;
  }
  .bg1-text {
    position: absolute;
    z-index: 1;
    text-align: left;
  }
  .bg2_3{
  }
  .bg4 {
    position: relative;
  }

  .bg4_1 {
    position: absolute;
    z-index: 1;
    height: auto;
  }
  .bg4_2 {
    position: absolute;
    z-index: 1;
    height: auto;
  }
  .bg4_3 {
    position: absolute;
    z-index: 1;
    height: auto;
  }
  .bg4_text {
    position: absolute;
    z-index: 1;
    padding-left: 9%;
    margin-top: -36%;
    width: 100%;
    text-align: left;
    color: #20184e;
    font-family: 'NanumSquareBold';
  }
  .bg4_btn {
    position: absolute;
    z-index: 1;
    margin-top: -33%;
    margin-left: 31%;
    text-decoration: none;
    color: white;
    background-color: #ff5100;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid none;
    border-radius: 20px;
    padding: 10px 25px 10px 25px;
    text-align: center;
    font-size: 20px;
  }
  .bg4_1 {
    width: 28%;
    margin-left: -1%;
  }
  .bg4_2 {
    width: 28%;
    margin-left: 27%;
  }
  .bg4_3 {
    width: 28%;
    margin-left: 55.3%;
  }
  .bg5 {
    position: relative;
  }
  .bg5_1 {
    position: absolute;
    z-index: 1;
    height: auto;
    border-radius: 3.7%;
    width: 65%;
    margin-top: 5px;
    margin-left: -99%;
  }
  .bg5_2 {
    position: absolute;
    z-index: 1;
    height: auto;
    border-radius: 3.7%;
    width: 65%;
    margin-top: 5px;
    margin-left: -32%;
  }
  .bg5_3 {
    position: absolute;
    z-index: 1;
    height: auto;
    border-radius: 3.7%;
    width: 65%;
    margin-top: 5px;
    margin-left: 35%;
  }
  .bg5_text {
    position: absolute;
    color: white;
    z-index: 1;
    margin-left: 49%;
    margin-top: -32%;
    text-align: right;
    font-family: 'NanumSquareBold';
  }

  /*=====================반응형 디자인 시작==========================*/

  @media screen and (max-width: 450px) {
    /*OTTERLAND Style components*/
    span {
      font-size: 16px;
      font-family: 'Aldrich', sans-serif;
      font-weight: 800;
    }
    /*=====================Backgroung 1 Start==========================*/
    .bg1-text {
      margin: -38.6% 10%;
      color: white;
      font-size: 15px;
      font-family: 'NanumSquareExtraBold';
    }

    /*=====================Backgroung 4 Start==========================*/
    .bg4_text {
      font-size: 15px;
    }
    .bg4_btn {
      font-size: 7px;
      padding: 4px 10px 4px 10px;
      margin-left: 27%;
      margin-top: -34%;
    }

    /*=====================Backgroung 5 Start==========================*/
    .bg5_text {
      font-size: 12px;
    }

    /*=====================WIDTH 450 END==========================*/

    /*=====================반응형 디자인 끝==========================*/
  }

  /*=====================WIDTH 451px ~ 768px START==========================*/

  @media screen and (min-width: 451px) and (max-width: 768px) {
    /*OTTERLAND Style components*/
    span {
      font-size: 20px;
    }
    .bg1-text {
      top: 21%;
      left: 19%;
      color: white;
      font-size: 1.2rem;
      font-family: 'NanumSquareExtraBold';
    }
    .bg4_text {
      font-size: 20px;
    }
    .bg4_btn {
      font-size: 12px;
      padding: 6px 15px 6px 15px;
      margin-left: 32%;
      margin-top: -34%;
    }
    .bg5_text {
      font-size: 20px;
    }
  }

  /*=====================WIDTH 769px ~ 1024px START==========================*/
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    overflow: scroll;
    /*OTTERLAND Style components*/
    span {
      font-size: 30px;
    }
    .bg1-text {
      top: 21%;
      left: 19%;
      color: white;
      font-size: 2rem;
      font-family: 'NanumSquareExtraBold';
    }
    .bg4_text {
      font-size: 30px;
    }
    .bg4_btn {
      font-size: 16px;
      padding: 8px 18px 8px 18px;
    }
    .bg5_text {
      font-size: 30px;
    }
  }

  @media screen and (min-width: 1025px) {
    /*OTTERLAND Style components*/
    span {
      font-size: 70px;
      font-family: 'Aldrich', sans-serif;
      font-weight: 800;
      padding-bottom: 10px;
      letter-spacing: 5px;
    }
    /*=====================Backgroung 1 Start==========================*/
    .bg1-text {
      margin: -38.6% 10%;
      color: white;
      font-size: 45px;
      font-family: 'NanumSquareExtraBold';
    }
    /*=====================Backgroung 4 Start==========================*/
    .bg4_text {
      font-size: 45px;
    }
    .bg4_btn {
      margin-top: -34%;
      margin-left: 32%;
      padding: 10px 25px 10px 25px;
      font-size: 20px;
    }
    /*=====================Backgroung 5 Start==========================*/
    .bg5_text {
      font-size: 45px;
    }

    /*=====================WIDTH 1024 END==========================*/

    /*=====================반응형 디자인 끝==========================*/
  }
`;
