/**
 * 회원 인증 관련 api 모듈 입니다.
 */

import api from './api.js';

/**
 * 로그인 확인 api 입니다.
 */
export const apiCheckLogin = async () => {
  const bodyData = '';
  const requestData = {
    data: bodyData,
  };

  const { data } = await api.post(
    '/auth/check/login',
    JSON.stringify(requestData)
  );

  return data.data;
};
