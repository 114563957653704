import styled from "styled-components";


const Privacy = () => {
  return (
    <Wrapper>
      <h1>오터랜드(Otterland) 개인정보처리방침</h1>
      <div className="text"><span>"주식회사 루몽스튜디오(이하 '회사')는 『개인정보보호법』 제30조에 의거하여
        '회원'의 개인정보 및 권익을 보호하기 위해 노력합니다. 개인정보와 관련한 '회원'의 고충을 원활하게 처리할 수 있도록 다음과 같은 방침을 두고 있습니다.
        단 '회사'는 '회원'의 귀책사유로 인해 노출된 정보에 대해서는 일체의 책임을 지지 않습니다."<br /><br/><br/></span>
        <h3>본 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.</h3><br/>
        1. 수집하는 개인정보의 항목 및 수집 방법<br /><br />
        2. 개인정보의 수집 및 이용목적<br /><br />
        3. 개인정보의 보유 및 이용기간<br /><br />
        4. 개인정보처리의 위탁에 관한 사항<br /><br />
        5. 개인정보의 제3자 제공에 관한 사항<br /><br />
        6. 정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항<br /><br />
        7. 개인정보 파기절차 및 방법<br /><br />
        8. 개인정보의 안전성 확보조치에 관한 사항<br /><br />
        9. 개인정보 보호책임자 및 담당자의 연락처<br /><br />
        10. 권익침해 구제방법<br /><br />
        11. 이용자의 권리·의무 및 행사방법<br /><br />
        12. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항<br /><br />
        13. 고지의 의무<br /><br />
        14. 개인정보 처리방침 변경에 관한 사항<br /><br />
        <h3>1. 수집하는 개인정보의 항목 및 수집 방법</h3><br/>
        ① 회사는 회원가입 및 원활한 고객 상담, 각종 서비스의 제공을 위해 서비스 통합
        아이디로 회원가입 시 아래와 같은 목적으로 개인정보를 수집하고 있습니다.<br /><br />
        (1) 개인정보 필수 수집 항목은 다음과 같습니다.<br /><br />
        ᆞ회원가입 시 : 휴대폰 번호, 비밀번호, 이메일, 닉네임, 성별, 연령대<br /><br />
        ᆞ소셜 회원가입 : 카카오 계정 정보<br /><br />
        (2) 개인정보 선택 수집 항목은 다음과 같습니다. (선택정보를 입력하지 않은 경우
        에도 서비스 이용 제한은 없습니다.)<br /><br />
        ᆞ추천인, 프로필 사진, 이메일<br /><br />
        (3) 서비스 이용 과정이나 기타 처리 과정에서 아래와 같은 정보들이 자동으로 생성
        되어 수집될 수 있습니다.<br /><br />
        ᆞ푸시 발송을 위한 단말에서 제공하는 휴대폰 device번호, 서비스 이용 기록, 접
        속 로그, 쿠키, 접속 IP 정보, 불량 이용 기록<br /><br />
        <h3>2. 개인정보의 수집 및 이용목적</h3><br />
        ① 회사는 수집한 개인정보를 다음의 목적을 위해 고지한 범위 내에서 사용 및 활
        용하며, 원칙적으로 이용자의 사전 동의 없이는 동의범위를 초과하여 이용하거나
        이용자의 개인정보를 외부에 공개하지 않습니다. (단, 이용자가 사전에 개인정보
        공개에 대하여 동의한 경우와 법령의 규정에 의거하거나 수사 목적으로 법령에 정
        해진 절차와 방법에 따라 수사기관의 요구가 있는 경우에는 외부에 공개할 수 있습
        니다.)<br /><br />
        (1) 서비스 제공에 관한 계약<br /><br />
        ᆞ웹사이트 및 애플리케이션 서비스 이용을 위한 컨텐츠 제공, 본인인증<br /><br />
        (2) 회원관리<br /><br />
        ᆞ회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용 방지와
        비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 분쟁 조정을 위한 기록보
        존, 불만처리 등 민원처리, 고지사항 전달<br /><br />
        (3) 맞춤 서비스 개발 및 마케팅, 광고에 개인정보의 활용<br /><br />
        ᆞ맞춤 서비스 개발 및 통계학적 특성에 따른 서비스 제공 및 광고게재, 이벤트 및
        광고성 정보 및 참여기회 제공, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통
        계<br /><br />
        <h3>3. 개인정보의 보유 및 이용기간</h3><br />
        ① 회사는 법령에 따른 개인정보 보유·이용기간 또는 이용자로부터 개인정보를 수
        집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
        각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br /><br />
        (1) 홈페이지 회원 가입 및 관리 : 회원 탈퇴 시까지 보유합니다.<br /><br />
        다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지 보유합니다.<br /><br />
        ᆞ관계 법령 위반에 따른 수사조사 등이 진행중인 경우에는 해당 수사조사 종료 시
        까지 보유합니다.<br /><br />
        「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약내용 및
        이행 등에 관한 기록<br /><br />
        ᆞ표시·광고에 관한 기록 : 6개월<br /><br />
        ᆞ계약 또는 청약철회 등의 공급기록 : 5년<br /><br />
        ᆞ소비자 불만 또는 분쟁처리에 관한 기록 : 3년<br /><br />
        「통신비밀보호법」에 따른 통신사실확인자료 보관<br /><br />
        · 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월<br /><br />
        (3) 이벤트 응모를 위해 주소 등의 개인정보를 제공한 경우에는 이벤트 상품 발송
        및 이벤트 응모 고객에 대한 고객응대를 위해 해당 이벤트의 종료시점으로부터 이
        벤트 안내를 통해 게시한 기간 동안 해당 개인정보를 보유합니다.<br /><br />
        (4) 개인정보 제공에 대해 동의철회(서비스 탈퇴)할 경우 '회사'에서 수집한 개인정
        보를 즉시 파기하며, 예외 규정을 제외하고 어떠한 목적으로도 사용할 수 없도록
        합니다.<br /><br />
        <h3>4. 개인정보의 제3자 제공에 관한 사항</h3><br/>
        주식회사 루몽스튜디오는 정보주체의 개인정보를 타인 및 타기업, 타기관에 제공
        하지 않습니다. 단, 다음의 경우는 예외로 합니다.<br /><br />
        - 회원의 동의가 있을 경우<br /><br />
        - 서비스 제공에 따른 요금 정산을 위하여 필요한 경우<br /><br />
        - 통계작성/학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아
        볼 수 없는 형태로 가공하여 제공하는 경우 : 개인정보 비식별화 조치<br /><br />
        - 다른 법률에 특별한 규정이 있는 경우<br /><br />
        - 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br /><br />
        <h3>5. 정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항</h3><br/>
        정보주체는 개인정보의 주체로써 다음과 같은 권리를 행사할 수 있습니다.<br /><br />
        (1) 정보주체는 언제든지 개인정보 열람, 정정, 삭제, 처리정지를 요구할 수 있습니
        다.<br /><br />
        (2) 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 권한위임자를 통해서도
        할 수 있습니다. 이 경우 개인정보 처리 방법에 관한 고시 별지 제11호 서식에 따른
        위임장을 제출해야 합니다.<br /><br />
        (3) 제1항에 따른 권리 행사는 개인정보보호법 시행령 제41조 제1항에 따라 서면,
        전자우편, 모사전송(FAX) 등의 방법으로 할 수 있습니다.<br /><br />
        (4) 정보주체의 권리에 따른 열람, 정정, 삭제, 처리정지 요구 시 주식회사 루몽스튜
        디오는 요구자가 본인인지 또는 정당한 대리인인지를 확인하고 있습니다.<br /><br />
        (5) 개인정보보호법 제35조 제4항, 제37조 제2항에 해당하는 경우 개인정보 열람,
        처리정지 요구는 거절될 수 있습니다. 이 경우 회사는 정보주체에게 지체없이 그
        사유를 안내합니다.<br /><br />
        (6) 개인정보보호법 외 다른 법령에서 개인정보가 수집 대상으로 명시되어 있는 경
        우 개인정보 정정, 삭제 요구를 할 수 없습니다.<br /><br />
        <h3>6. 개인정보 파기절차 및 방법</h3><br/>
        ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되
        었을 때에는 지체없이 해당 개인정보를 파기합니다.<br/><br/>
        (1) 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮
        겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 사유에 따라
        일정 기간 저장된 후 파기됩니다.<br/><br/>
        ② 파기방법<br/><br/>
        (2) 종이에 출력된 개인정보 및 접수된 서류는 분쇄하거나 소각을 통하여 파기하고,
        전자적 파일 형태로 저장된 개인정보는 복구할 수 없는 기술적 방법을 사용하여 삭
        제합니다.<br/><br/>
        <h3>7. 개인정보의 안전성 확보조치에 관한 사항</h3><br/>
        ① 회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 위
        조, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
        대책을 강구 하고 있습니다.<br/><br/>
        (1) 기술적 보호대책ᆞ휴대폰 번호와 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있
        으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.<br/><br/>
        ᆞ회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼
        손되는 것을 막기 위해 최선을 다하고 있습니다.<br/><br/>
        ᆞ회사는 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프
        로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록
        방지하고 있습니다.<br/><br/>
        ᆞ회사는 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 송수신
        할 수 있도록 하고 있습니다.<br/><br/>
        ᆞ회사는 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으
        며, 이외 시스템적인 보안성을 확보하기 위해 가능한 모든 기술적 장치를 갖추려
        노력하고 있습니다.<br/><br/>
        (2) 관리적 보호대책<br/><br/>
        ᆞ회사는 개인정보 관련 취급 직원을 담당자에 한정시키고 이를 위한 별도의 비밀
        번호를 부여하고, 정기적으로 갱신하고 있습니다.<br/><br/>
        ᆞ회사는 주기적으로 담당자들을 교육하고 있으며, 이용자의 개인정보 취급, 관리
        를 강조하고 있습니다.<br/><br/>
        ᆞ회사는 사내 개인정보보호 관련부서 등을 통해서 회사의 개인정보처리방침 이
        행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로
        잡을 수 있도록 노력하고 있습니다.<br/><br/>
        <h3>8. 개인정보 보호책임자 및 담당자의 연락처</h3><br/>
        ① 이용자는 회사의 서비스를 이용하는 과정에서 발생하는 모든 개인정보보호 관
        련 민원을 개인정보 보호책임자·담당자에게 신고할 수 있습니다. 회사는 이용자들
        의 신고사항에 대해 신속하게 답변을 드릴 것입니다.<br/><br/>
        개인정보 보호책임자<br/><br/>
        이름 : 이선진<br/><br/>
        소속 : 주식회사 루몽스튜디오<br/><br/>
        직책 : 대표이사<br/><br/>
        전화 : 000000-0000000<br/><br/>
        메일 : yatchman.lee@rumong.kr<br/><br/>
        개인정보 보호담당자<br/><br/>
        이름 : 이선진<br/><br/>
        소속 : 주식회사 루몽스튜디오<br/><br/>
        직책 : 대표이사<br/><br/>
        전화 : 000000-0000000<br/><br/>
        메일 :yatchman.lee@rumong.kr<br/><br/>
        <h3>9. 권익침해 구제방법</h3><br/>
        ① 이용자는 개인정보침해로 인한 구제를 받기 위하여 개인
        정보분쟁조정위원회, 개인정보침해신고센터 등에 분쟁 해결이나 상담 등을 신청
        할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관
        에 문의하시기 바랍니다.<br/><br/>
        (1) 개인정보분쟁조정위원회: (국번없이) 1833-6972 (www.kopico.go.kr)<br/><br/>
        (2) 개인정보침해신고센터: (국번없이) 118 (privacy.kisa.or.kr)<br/><br/>
        (3) 대검찰청: (국번없이) 1301 (www.spo.go.kr)<br/><br/>
        (4) 경찰청: (국번없이) 182 (cyberbureau.police.go.kr)<br/><br/>
        <h3>11. 이용자의 권리·의무 및 행사방법</h3><br/>
        (아래 내용은 플랫폼 구축시 기능을 보고 제작성 필요)<br/><br/>
        ① 이용자 자신의 개인정보를 홈페이지 내에서 열람, 정정, 처리 정지 및 삭제를 할
        수 있습니다.<br/><br/>
        (1) 개인정보 열람 : 마이페이지<br/><br/>
        (2) 개인정보 편집 : 마이페이지 {'>'} 프로필 수정<br/><br/>
        (3) 개인정보 삭제 : 마이페이지 {'>'} 프로필 수정 {'>'} 회원탈퇴<br/><br/>
        ② 서면, 전자우편, 전화 등을 통하여 개인정보 열람, 정정, 처리 정지 및 삭제를 요
        구할 수 있습니다.<br/><br/>
        ③ 개인정보의 오류 등에 대한 정정 또는 삭제를 요청한 경우에는 정정 또는 삭제
        를 완료하기 전까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br/><br/>
        ④ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로
        명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/><br/>
        ⑤ 이용자 권리 행사는 위임을 받은 자 등 대리인을 통해서도 요구할 수 있습니다.
        이 경우 "개인정보 처리 방법에 관한 고시(제2020-7호)" 별지 제11호 서식에 따른
        위임장을 제출하셔야 합니다.<br/><br/>
        ⑥ 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시
        열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br/><br/>
        ⑦ 회사는 이용자의 개인정보 열람, 정정, 삭제 등 요구의 처리 방법을 개인정보 수
        집방법보다 쉽게 할 수 있도록 필요한 조치를 하고 있습니다.<br/><br/>
        ⑧ 만 14세 미만의 아동의 개인정보는 수집하지 않습니다.<br/><br/>
        <h3>12. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h3><br/>
        ① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하
        고 수시로 불러오는 '쿠키(cookie)' 를 사용합니다.<br/><br/>
        ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우
        저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되
        기도 합니다.<br/><br/>
        (1) 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이
        용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제
        공을 위해 사용됩니다.<br/><br/>
        (2) 쿠키의 설치·운영 및 거부: 웹브라우저 상단의 도구 {'>'} 인터넷 옵션 {'>'} 개인정보 메
        뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.<br/><br/>
        (3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.<br/><br/>
        <h3>13. 고지의 의무</h3><br/>
        "1 본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우
        개정 최소 7일 전에 ‘공지사항’을 통해 사전 공지를 할 것입니다.
        다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대
        한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요 시 이용자 동의를 다시 받
        을 수도 있습니다."<br/><br/>
        <h3>14. 개인정보 처리방침 변경에 관한 사항</h3><br/>
        (1) 공고일자 : 2021년 09월 21일<br/><br/>
        (2) 시행일자 : 2021년 09월 21일<br/><br/></div>
    </Wrapper>
  );
};

export default Privacy;

const Wrapper = styled.div`
margin-left: 25%;
max-width: 50%;
min-height: 80vh;
.text{
  text-align:left;
  font-size: 20px;
  padding-bottom: 30px;
}
h1{
  font-size: 35px;
  padding-top: 30px;
  padding-bottom: 30px;
}
span{
  font-size: 22px;
}
`;