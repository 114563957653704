import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import apiCode from '../lib/api/apiCode';
import { apiMyPoint, apiPointCharge } from '../lib/api/apiWallet';
import { Header } from 'antd/lib/layout/layout';

//point -> buy_info 순으로 제작했습니다. Buy_info부분에 pg사가 들어갈 예정입니다.
const Point = props => {
  const navigate = useNavigate();

  const [cost, setNumber] = useState(0);
  const [myPoint, setMyPoint] = useState(0);
  const [addPoint, setPoint] = useState(0);

  const myPoint2 = myPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const addPoint2 = addPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  

  // 내 포인트 조회 api 호출
  const apiCallMyPoint = async () => {
    const data = await apiMyPoint();
    // 세션이 끊어진 상태
    if (data.resultCode === apiCode.error.needLogin) {
      navigate('/login');
      return;
    }

    if (data.resultCode === apiCode.error.success) {
      setMyPoint(data.point);
    }
  };

  // 포인트 구매하기 버튼 클릭
  const handlePurchase = async () => {
    const data = await apiPointCharge(addPoint);
    // 세션이 끊어진 상태
    if (data.resultCode === apiCode.error.needLogin) {
      navigate('/wallet/pointcharge');
      return;
    }

    if (data.resultCode === apiCode.error.success) {
      navigate('/mypage');
    }
  };

  const on5000 = () => {
    setNumber('5,000');
    setPoint(5000);
  };
  const on10000 = () => {
    setNumber('10,000');
    setPoint(10000);
  };
  const on30000 = () => {
    setNumber('30,000');
    setPoint(30000);
  };
  const on50000 = () => {
    setNumber('50,000');
    setPoint(50000);
  };
  const on70000 = () => {
    setNumber('70,000');
    setPoint(70000);
  };
  const on100000 = () => {
    setNumber('100,000');
    setPoint(100000);
  };
  const on300000 = () => {
    setNumber('300,000');
    setPoint(300000);
  };
  const on500000 = () => {
    setNumber('500,000');
    setPoint(500000);
  };

  useEffect(() => {
    apiCallMyPoint();
  }, []);

  return (
    <Wrapper>
      <ul className="info_main">
        <li>
          <h1>포인트 충전</h1>
        </li>
      </ul>

      <ul className="info">
        <li>
          <text>보유 포인트</text>
        </li>
        <li>
          <text>{myPoint2} P</text>
        </li>
      </ul>

      <ul className="info">
        <li>
          <text>충전 포인트</text>
        </li>
        <li>
          <text>{addPoint2} P</text>
        </li>
      </ul>

      <ul className="money1">
        <li>
          <text></text>
        </li>
        <li>
          <button className="btn1" onClick={on5000}>
            + 5,000
          </button>
          <button className="btn1" onClick={on10000}>
            + 10,000
          </button>
          <button className="btn1" onClick={on30000}>
            + 30,000
          </button>
          <button className="btn1" onClick={on50000}>
            + 50,000
          </button>
        </li>
      </ul>

      <ul className="money2">
        <li>
          <text></text>
        </li>
        <li>
          <button className="btn2" onClick={on70000}>
            + 70,000
          </button>
          <button className="btn2" onClick={on100000}>
            + 100,000
          </button>
          <button className="btn2" onClick={on300000}>
            + 300,000
          </button>
          <button className="btn2" onClick={on500000}>
            + 500,000
          </button>
        </li>
      </ul>

      <ul className="cost">
        <li>
          <text></text>
        </li>
        <li>
          <h1 className="cost_text">₩{cost}</h1>
        </li>
      </ul>

      <ul className="menu">
        <li>
          <Link to={'/buy_cube'}>
            <button className="buy_btn">뒤로가기</button>
          </Link>
        </li>
        <li>
        <button className="buy_btn2" onClick={handlePurchase}>
        구매하기
      </button>
        </li>
      </ul>
    </Wrapper>
  );
};
export default Point;
const Wrapper = styled.div`
text-decoration: none;
display: block;
font-family: 'nanumsquare';
min-height: 80vh;
@media only screen and (max-width:600px){
  ul{
    width: 75%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 15%;
  }
  li+li{
  }
  text{
    font-weight: 600;
    font-size: 14px;
  }
  h1{
    font-size: 18px;
  }
  .info_main{
    padding-top: 40px;
    padding-bottom: 10px;
  }
  .info{
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom:25px;
    border-top: 1px solid lightgray;
  }
  .money1{
    padding-top: 30px;
    justify-content: center;
  }
  .money2{
    padding-bottom: 50px;
    justify-content: center;
  }
  .cost{
    border: 1px solid none;
    background: #F5F5F5;
    padding: 10px 10px;
  }
  .cost_text{
    font-size: 18px;
    margin-right: 10px;
  }
  .btn1{
    background: none;
    font-size: 14px;
    padding: 12px 12px;
    width: 100px;
    margin-left: 23px;
    margin-right: 23px;
    margin-bottom: 10px;
    border: 3px solid #ff5100;
    color: #ff5100;
    border-radius: 8px;
  }
  .btn2{
      background: none;
      font-size: 14px;
      padding: 12px 12px;
      width: 100px;
      margin-left: 23px;
      margin-right: 23px;
      margin-bottom: 10px;
      border: 3px solid #ff5100;
      color: #ff5100;
      border-radius: 8px;
  }
  .menu{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .buy_btn{
    font-size: 15px;
    background: none;
    padding: 8px 15px 8px 15px;
    border-radius: 8px;
  }
  .buy_btn2{
    font-size: 15px;
    background: #191554;
    color: white;
    border: none;
    padding: 10px 15px 10px 15px;
    border-radius: 8px;
   }
  }
@media only screen and (min-width: 601px) and (max-width:768px){
  ul{
    width: 75%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 15%;
  }
  li+li{
  }
  text{
    font-weight: 600;
    font-size: 20px;
  }
  h1{
    font-size: 30px;
  }
  .info_main{
    padding-top: 60px;
    padding-bottom: 10px;
  }
  .info{
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom:25px;
    border-top: 1px solid lightgray;
  }
  .money1{
    padding-top: 50px;
    justify-content: center;
  }
  .money2{
    padding-bottom: 50px;
    justify-content: center;
  }
  .btn1{
    background: none;
    font-size: 16px;
    padding: 12px 12px;
    width: 120px;
    margin-left: 26px;
    margin-right: 26px;
    margin-bottom: 10px;
    border: 3px solid #ff5100;
    color: #ff5100;
    border-radius: 8px;
  }
  .btn2{
      background: none;
      font-size: 16px;
      padding: 12px 12px;
      width: 120px;
      margin-left: 26px;
      margin-right: 26px;
      margin-bottom: 10px;
      border: 3px solid #ff5100;
      color: #ff5100;
      border-radius: 8px;
  }
  .menu{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .cost{
    border: 1px solid none;
    background: #F5F5F5;
    padding: 10px 10px;
  }
  .cost_text{
    font-size: 20px;
    margin-right: 15px;
  }
  .buy_btn{
    font-size: 20px;
    background: none;
    padding: 10px 25px 10px 25px;
    border-radius: 8px;
  }
  .buy_btn2{
    font-size: 20px;
    background: #191554;
    color: white;
    border: none;
    padding: 12px 25px 12px 25px;
    border-radius: 8px;
   }
  }
@media only screen and (min-width: 769px) and (max-width:1199px){
  ul{
    width: 90%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-left: 5%;
  }
  li+li{
  }
  text{
    font-weight: 600;
    font-size: 25px;
  }
  h1{
    font-size: 35px;
  }
  .info_main{
    padding-top: 80px;
    padding-bottom: 10px;
  }
  .info{
    margin-top: 60px;
    padding-top: 25px;
    padding-bottom:25px;
    border-top: 1px solid lightgray;
  }
  .money1{
    padding-top: 80px;
  }
  .money2{
    padding-bottom: 50px;
  }
  .cost{
    font-size: 25px;
    border: 1px solid none;
    background: #F5F5F5;
    padding: 10px 10px;
  }
  .cost_text{
    font-size: 30px;
    margin-right: 30px;
  }
  .btn1{
    background: none;
    font-size: 20px;
    padding: 12px 12px;
    width: 150px;
    margin-left: 30px;
    border: 3px solid #ff5100;
    color: #ff5100;
    border-radius: 8px;
  }
  .btn2{
      background: none;
      font-size: 20px;
      padding: 12px 12px;
      width: 150px;
      margin-top: 10px;
      margin-left: 30px;
      border: 3px solid #ff5100;
      color: #ff5100;
      border-radius: 8px;
  }
  .menu{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .buy_btn{
    font-size: 25px;
    background: none;
    padding: 10px 30px 10px 30px;
    border-radius: 8px;
  }
  .buy_btn2{
    font-size: 25px;
    background: #191554;
    color: white;
    border: none;
    padding: 10px 30px 10px 30px;
    border-radius: 8px;
   }
  }
@media only screen and (min-width: 1200px){
ul{
  width: 75%;
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin-left: 15%;
}
li+li{
}
text{
  font-weight: 600;
  font-size: 25px;
}
h1{
  font-size: 40px;
  border: none;
}
.info_main{
  padding-top: 100px;
  padding-bottom: 10px;
}
.info{
  margin-top: 60px;
  padding-top: 25px;
  padding-bottom:25px;
  border-top: 1px solid lightgray;
}
.money1{
  padding-top: 80px;
  padding-bottom: 20px;
  justify-content: space-between;
}
.money2{
  padding-bottom: 50px;
  justify-content: space-between;
}
.cost{
  font-size: 25px;
  border: 1px solid none;
  background: #F5F5F5;
  padding: 10px 10px;
}
.cost_text{
  font-size: 30px;
  margin-right: 30px;
}
.btn1{
  background: none;
  font-size: 20px;
  padding: 12px 12px;
  width: 200px;
  margin-left: 50px;
  border: 3px solid #ff5100;
  color: #ff5100;
  border-radius: 8px;
}
.btn2{
    background: none;
    font-size: 20px;
    padding: 12px 12px;
    width: 200px;
    margin-left: 50px;
    border: 3px solid #ff5100;
    color: #ff5100;
    border-radius: 8px;
}
.menu{
  padding-top: 50px;
  padding-bottom: 50px;
}
.buy_btn{
  font-size: 25px;
  background: none;
  padding: 10px 30px 10px 30px;
  border-radius: 8px;
}
.buy_btn2{
  font-size: 25px;
  background: #191554;
  color: white;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 8px;
 }
}
`;