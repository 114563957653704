/**
 * api 응답 시 받는 서비스 및 에러 코드 입니다.
 */

const apiCode = {
  service: {
    loginType: {
      kakao: '100101',
    },
    tradeType: {
      point: '110101',
      cube: '110102',
    },
    bundle_status: {
      normal: '120101',
      onSele: '120102',
    },
    noticeType: {
      normal: '130101',
    },
    mapStyle: {
      baseMap: '101',
      satellite: '102',
      threeD: '103',
    },
  },

  error: {
    success: '0000',
    notUser: '0101', // 해당 유저가 존재하지 않습니다.
    needLogin: '0102', // 로그인이 필요합니다.
    alreadyLogin: '0103', // 이미 로그인 중입니다.
    existEmail: '0104', // 이미 존재하는 이메일 주소 입니다.
    notEmail: '0105', // 소셜 로그인에 이메일 정보가 없습니다.
    failKakaoLogin: '0106', // 카카오 로그인에 실패하였습니다.
    existNickName: '0107', // 이미 존재하는 닉네임 입니다.
    notReferralCode: '0108', // 존재하지 않는 추천인 코드 입니다.
    signUp: '0109', // 회원가입 로그인 입니다.
    failPointCharge: '0201', // 포인트 충전에 실패 하였습니다.
    failCreateKasWallet: '0202', // kas 지갑 생성에 실패 하였습니다.
    soldCube: '0301', // 이미 판매된 큐브 입니다.
    notEnoughPoint: '0302', // 포인트가 부족합니다.
    failBuyCube: '0303', // 큐브 구매에 실패 하였습니다.
    failCreateNft: '0304', // nft 생성에 실패 하였습니다.
    request: '9101', // 잘못된 요청 입니다.
    systemError: '9999', // 알수없는 오류가 발생 하였습니다.
  },
};

export default apiCode;
