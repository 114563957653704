import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';



const Fail = () => {
  return (
    <Wrapper>
      <h1>결제 실패</h1>
      <Link to={'/point'}>
        <button>확인</button>
      </Link>
    </Wrapper>
  );
};
export default Fail;
const Wrapper = styled.div`
min-height:80vh;
diaplay: flex;
  .success {
    width: 100%;
  }
  button {
    width: 25%;
    border: 0;
    text-align: center;
    font-family: 'NanumSquareBold';
    font-size: 20px;
    color: white;
    background: #ff5100;
    border-radius: 8px;
    padding: 12px 20px 12px 20px;
  }
`;