import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './pages/Main';
import Ranking from './pages/Ranking';
import Notice from './pages/Notice';
import P_Service from './pages/P_Service';
import Privacy from './pages/Privacy';
import Service from './pages/Service';
import CS_Center from './pages/CS_Center';
import Cube from './Cube/Cube';
import Cube_Success from './Cube/Cube_Success';
import Buy_Cube from './Cube/Buy_Cube';
import Buy_History from './Cube/Buy_History';
import MyCube from './Cube/MyCube';
import Login from './account/Login';
import Signup from './account/Signup';
import Signup_Success from './account/Signup_Success';
import Fail from './Points/Fail';
import Success from './Points/Success';
import Point from './Points/Point';
import Buy_Info from './Points/Buy_Info';
import Mypage from './MyPage/Mypage';
import Edit_Profile from './MyPage/Edit_Profile';
import { useState } from 'react';

export default function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          {/*eslint-disable-next-line*/}
          <Route path="/" element={<Main />} />
          {/*eslint-disable-next-line*/}
          <Route path="/cube" element={<Cube />} />
          {/*eslint-disable-next-line*/}
          <Route path="/ranking" element={<Ranking />} />
          {/*eslint-disable-next-line*/}
          <Route path="/notice" element={<Notice />} />
          {/*eslint-disable-next-line*/}
          <Route path="/login" element={<Login />} />
          {/*eslint-disable-next-line*/}
          <Route path="/signup" element={<Signup />} />
          {/*eslint-disable-next-line*/}
          <Route path="/p_service" element={<P_Service />} />
          {/*<Route path="/privacy" element={<Privacy />} />*/}
          <Route path="/privacy" element={<Privacy />} />
          {/*<Route path="/privacy" element={<Privacy />} />*/}
          {/*eslint-disable-next-line*/}
          <Route path="/service" element={<Service />} />
          {/*eslint-disable-next-line*/}
          <Route path="/cs_center" element={<CS_Center />} />
          {/*eslint-disable-next-line*/}
          <Route path="/buy_cube" element={<Buy_Cube />} />
          {/*eslint-disable-next-line*/}
          <Route path="/success" element={<Success />} />
          {/*eslint-disable-next-line*/}
          <Route path="/fail" element={<Fail />} />
          {/*eslint-disable-next-line*/}
          <Route path="/point" element={<Point />} />
          {/*eslint-disable-next-line*/}
          <Route path="/buy_info" element={<Buy_Info />} />
          {/*eslint-disable-next-line*/}
          <Route path="/cube_success" element={<Cube_Success />} />
          {/*eslint-disable-next-line*/}
          <Route path="/mypage" element={<Mypage />} />
          {/*eslint-disable-next-line*/}
          <Route path="/signup_success" element={<Signup_Success />} />
          {/*eslint-disable-next-line*/}
          <Route path="/edit_profile" element={<Edit_Profile />} />
          {/*eslint-disable-next-line*/}
          <Route path="/mycube" element={<MyCube />} />
          <Route path="/buy_history" element={<Buy_History />} />x
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
