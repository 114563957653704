import React from 'react';
import cube4 from '../img/cube4.png';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Success = () => {
  return (
    <Wrapper>
      <img src={cube4} className="success" />
      <Link to={'/Cube'}>
        <button>확인</button>
      </Link>
    </Wrapper>
  );
};
export default Success;

const Wrapper = styled.div`
  .success {
    width: 100%;
  }
  button {
    width: 25%;
    border: 0;
    text-align: center;
    font-family: 'NanumSquareBold';
    font-size: 20px;
    color: white;
    background: #ff5100;
    border-radius: 8px;
    padding: 12px 20px 12px 20px;
  }
`;
