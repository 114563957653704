import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';
import { CgProfile } from 'react-icons/cg';
import apiCode from '../lib/api/apiCode';
import { apiCheckLogin } from '../lib/api/apiAuth';

const Header = () => {
  const navigate = useNavigate();

  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  // 로그인 확인 api 호출
  const apiCallCheckLogin = async () => {
    const data = await apiCheckLogin();
    if (data.resultCode !== apiCode.error.success) {
      setIsLogin(false);
      navigate('/login');
      return false;
    }
    setIsLogin(true);
    return true;
  };

  const handleToggleOpen = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  useEffect(() => {
    apiCallCheckLogin();
  }, []);

  return (
    <>
      <StyledHeader>
        <div className="nav_logo">
          <Link to={'/'} className="nav-logo-link">
            <h1>OTTERLAND</h1>
          </Link>
        </div>

        <NavMenu isToggleOpen={isToggleOpen}>
          <li>
            <Link to={'/'} className="nav-menu-list">
              홈
            </Link>
          </li>
          <li>
            <Link to={'/cube'} className="nav-menu-list">
              큐브
            </Link>
          </li>
          {/*<li>
            <Link to={"/ranking"} className="nav-menu-list">
              랭킹
            </Link>
            /li>*/}
          {/*<li>
            <Link to={'/notice'} className="nav-menu-list">
              공지
            </Link>
          </li>*/}
        </NavMenu>
        <NavMenu2 isToggleOpen={isToggleOpen} isLogin={isLogin}>
          <li>
            <Link to={'/login'} className="nav-menu-list2">
              Login
            </Link>
          </li>
          <li>
            <Link to={'/signup'} className="nav-menu-list3">
              Sign Up
            </Link>
            <Link to={'/mypage'} className="nav-menu-list4">
              <CgProfile size="35" />
            </Link>
          </li>
        </NavMenu2>
        <FaBars className="menuToggleBtn" onClick={handleToggleOpen} />
      </StyledHeader>
    </>
  );
};

export default Header;

const StyledHeader = styled.header`
  background: black;
  width: 100%;
  padding: 20px 0px 0px 0px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-size: 30px;
    font-family: 'Aldrich', sans-serif;
  }
  .nav_logo {
    padding: 0px 12px;
    padding-left: 90px;
    .nav-logo-link {
      text-decoration: none;
      color: #ff5100;
      font-weight: bold;
    }
  }
  .menuToggleBtn {
    display: none;
    color: white;
    font-size: 24px;
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }

  @media screen and (max-width: 835px) {
    flex-direction: column;
    align-items: flex-start;
    .menuToggleBtn {
      display: block;
      justify-content: center;
      margin-top: 4px;
    }
    .nav_logo {
      margin-bottom: -5px;
      padding-left: 40px;
    }
    h1 {
      font-size: 30px;
      font-family: 'Aldrich', sans-serif;
      justify-content: center;
      align-items: center;
    }
  }
`;
const NavMenu = styled.ul`
  list-style: none;
  display: flex;
  //padding-right: 40px;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0em;

  li {
    &:hover {
      cursor: pointer;
      background: none;
      border-radius: 4px;
    }
    padding: 0px 30px 0px 30px;
  }
  .nav-menu-list {
    text-decoration: none;
    color: white;
    display: block;
    padding: 10px 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 835px) {
    display: ${props => (props.isToggleOpen ? 'block' : 'none')};
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    font-size: 15px;
    &:hover: {
      background: black;
    }
  }
`;
const NavMenu2 = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  padding-right: 70px;
  font-size: 18px;
  font-weight: 800;
  li {
    &:hover {
      cursor: pointer;
      background: none;
      border-radius: 4px;
    }
  }
  .nav-menu-list2 {
    text-decoration: none;
    color: #ff5100;
    align-content: center;
    display: ${props => (props.isLogin ? 'none' : 'block')}; // 로그인
    margin-right: 10px;
    padding: 8px 20px 8px 20px;
    flex-direction: row;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid #ff5100;
    border-radius: 10px;
  }
  .nav-menu-list3 {
    text-decoration: none;
    color: white;
    display: ${props => (props.isLogin ? 'none' : 'block')}; // 로그인
    padding: 8px 10px;
    border: none;
    flex-direction: row;
    font-family: 'Montserrat', sans-serif;
    background: #ff5100;
    border-radius: 10px;
  }
  .nav-menu-list4 {
    text-decoration: none;
    color: #ff5100;
    display: ${props => (props.isLogin ? 'block' : 'none')}; // 회원정보
    margin-left: 110px;
    padding-right: 50px;
    flex-direction: row;
  }
  @media screen and (max-width: 835px) {
    display: ${props => (props.isToggleOpen ? 'block' : 'none')};
    flex-direction: column;
    align-items: flex-center;
    width: 100%;
    padding-left: 70px;
    font-weight: 800;
    font-size: 15px;
    .nav-menu-list2 {
      text-decoration: none;
      color: white;
      font-family: 'Montserrat', sans-serif;
      border: none;
      margin-left: 10px;
    }
    .nav-menu-list3 {
      text-decoration: none;
      color: white;
      font-family: 'Montserrat', sans-serif;
      border: none;
      background: none;
    }
  }
`;
